import React from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Input, Row } from 'ui'
import { Page } from '../../../../components'
import { useProjectId } from '../../../../contexts'
import { trpc } from '../../../../lib'
import { CheckConnectivityWidget } from '../postgres-managed-db/check-connectivity-widget'
import { RunMigrationsWidget } from '../postgres-managed-db/run-migrations-widget'

const ShowDestinationPage = () => {
  const params = useParams()
  const projectId = useProjectId()

  const { data: show } = trpc.destinations.managedPostgresDatabases.show.useQuery(
    {
      projectId,
      id: params.id!,
    },
    { enabled: !!params.id },
  )

  const existingPipes: Array<{
    abiFragment: {
      id: string
      sighash: string
      address: string
    }
  }> = []

  const newPipes: Array<{
    abiFragment: {
      id: string
    }
  }> = []

  return (
    <Page
      className="space-y-8"
      title="Credential"
      // title={show?.credential?.name}
    >
      <div className="grid grid-cols-2 items-stretch gap-4">
        {/* <RunMigrationsWidget
          className="col-span-1"
          projectID={projectId}
          connectionID={show?.credential?.id}
        />
        <CheckConnectivityWidget
          projectID={projectId}
          connectionID={show?.credential?.id}
          className="col-span-1"
        /> */}
      </div>
      {/* <table className="w-full max-w-24">
        <tbody>
          <tr>
            <td>Host</td>
            <td className="font-mono">{show?.credential?.host}</td>
          </tr>
          <tr>
            <td>User</td>
            <td className="font-mono">{show?.credential?.user}</td>
          </tr>
          <tr>
            <td>Database</td>
            <td className="font-mono">{show?.credential?.database}</td>
          </tr>
        </tbody>
      </table> */}
      {/* <CardWithContent
        title="Synchronize Views"
        description={'Synchronize your chainpipe views with your remote database.'}
      >
        <Button>Synchronize Chainpipe Views with Schema</Button>
        <br />
        <br />
        <p>Last Checked: never</p>
      </CardWithContent> */}
      <Divider />
      {/* <Row className="items-center">
        <h2>Sources Not Linked</h2>
        <Button>Link All</Button>
      </Row>
      <Table>
        <THead>
          <Tr>
            <Th>Source</Th>
            <Th>Contract</Th>
            <Th>Create</Th>
          </Tr>
        </THead>
        <TBody>
          {(show?.missingPipes || []).map((pipe) => (
            <Tr key={pipe.id}>
              <Td>hi</Td>
              <Td>{pipe.projectEvmContractCoverageClaim.address}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>

      <Divider />
      <h2>Pipes into this destination</h2>

      <Table>
        <THead>
          <Tr>
            <Th>Contract</Th>
            <Th>Source</Th>
            <Th>Pipe Name</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td>Contract Name</Td>
            <Td>
              <div className="space-y-1">
                <code className="font-medium">event GovernanceUpdated(uint256)</code>
                <br />
                <code className="text-sm text-slate-500">0xabcdef</code>
              </div>
            </Td>
            <Td>
              <code>table_name</code>
            </Td>
          </Tr>
        </TBody>
      </Table> */}
    </Page>
  )
}

export default ShowDestinationPage
