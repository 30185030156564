import type React from 'react'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export type BFC<P = {}> = React.FunctionComponent<P & { className?: string; children?: any; style?: any }>

export { clsx } from 'clsx'
