import { createWSClient, httpLink, loggerLink, splitLink, wsLink } from '@trpc/client'
import superjson from 'superjson'
import { trpc } from './lib/trpc'

const wsURL = `wss://${window.location.host}/trpc`

export const wsClient = createWSClient({
  url: wsURL,
  retryDelayMs(attemptIndex) {
    if (attemptIndex > 2) {
      return 5000
    }
    return 1000
  },
})

export const trpcClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
    }),

    splitLink({
      condition(op) {
        return op.type === 'subscription'
      },
      true: wsLink({ client: wsClient }),

      false: httpLink({
        url: '/trpc',
        headers(ctx) {
          const headers: Record<string, any> = {}
          const authToken: string | undefined = undefined
          return headers
        },
      }),
    }),
  ],

  transformer: superjson,
})
