import React from 'react'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { Debug, useCenterAbsoluteUrl, useToast } from 'ui'
import { type RouterOutputs, trpc } from '../lib/trpc'

type UserType = Exclude<RouterOutputs['auth']['whoami']['user'], null>

/**
 * Authentication context.
 */
export const AuthContext = React.createContext<{
  user: UserType | null
}>({
  user: null,
})

/**
 * Requires an authenticated user, or redirects to a login screen
 */
export const AuthGate = ({ children }: { children: React.ReactNode }) => {
  const auth = trpc.auth.whoami.useQuery()
  const [user, setUser] = React.useState<UserType | null>(null)
  const navigate = useNavigate()
  const {
    notify,
    toast: {},
  } = useToast()

  const loginUrl = useCenterAbsoluteUrl({
    subdomain: 'auth',
    path: '/login',
  })

  React.useEffect(() => {
    if (auth.isError || auth.data?.authenticated === false) {
      console.info('Not authenticated... redirecting')
      window.location.href = loginUrl
      return
    }

    // Auth is success by now
    if (auth.data && auth.data.user && auth.data.user.id) {
      setUser(auth.data.user)
      Sentry.setUser({ id: auth.data.user.id, email: auth.data.user.email })
    }
  }, [auth.error, loginUrl, auth.data, auth.isSuccess, setUser, navigate])

  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
