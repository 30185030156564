import type { CenterSubdomain } from 'common'

function getCenterApexFromWindowOrFail() {
  const currentUrl = new URL(window.location.href)
  const centerApexParts = currentUrl.hostname.split('.')
  // If at the apex this will be like ["domain", "com"], else ["subdomain", "domain", "com"]
  const isHostedAtApex = centerApexParts.length == 2
  const centerApexComponents = isHostedAtApex ? centerApexParts : centerApexParts.slice(1)
  const centerApex = centerApexComponents.join('.')
  return centerApex
}

function getCenterApex() {
  let centerApex: string | undefined = undefined
  if (typeof window !== 'undefined') {
    centerApex = getCenterApexFromWindowOrFail()
  }
  if (!centerApex) {
    centerApex = 'center.app'
  }

  return centerApex
}

export function useCenterApex() {
  return getCenterApex()
}

type ExtractHostAndPath<
  Subdomain extends CenterSubdomain | null,
  UrlPath extends string,
> = Subdomain extends CenterSubdomain ? `${Subdomain}.${string}${UrlPath}` : `${string}${UrlPath}`

export function useCenterAbsoluteUrl<
  const Subdomain extends CenterSubdomain | null,
  const Path extends string,
>(options: {
  subdomain: Subdomain
  path: Path
}): `https://${ExtractHostAndPath<Subdomain, Path>}` {
  const centerApex = getCenterApex()

  if (options.subdomain) {
    const hostAndPath = `${options.subdomain}.${centerApex}${options.path}` as ExtractHostAndPath<Subdomain, Path>
    const fullPath = `https://${hostAndPath}` as const
    return fullPath
  }

  const hostAndPath = `${centerApex}${options.path}` as ExtractHostAndPath<Subdomain, Path>
  const fullPath = `https://${hostAndPath}` as const
  return fullPath
}
