import type { NetworkID } from '../network-id'

export type AbiFragmentIndexingProgressType = {
  network: NetworkID
  sighash: string
  name: string
  fullName: string
  progressRatio: number
  missingBlockspace: number
  coveredBlockspace: number
  active: boolean
  id: string
  fragmentType: 'event'
  totalCount: number
}

export type SerializedEvmContractType = {
  name: string
  network: NetworkID
  id: string
  address: string
}

export type State = {
  abiFragments: Record<string, AbiFragmentIndexingProgressType>
  evmContract: SerializedEvmContractType
}

export type ActionTypes = {
  SetInitialState: {
    type: 'SET_INITIAL_STATE'
    payload: State
  }

  SetInitialStateCounts: {
    type: 'SET_INITIAL_STATE_COUNTS'
    payload: Array<{ sighash: string; count_events: string }>
  }

  UpdateProgress: {
    type: 'UPDATE_PROGRESS'
    payload: {
      network: NetworkID
      address: string | undefined
      sighash: string
      blockRange: string
      blockCount: number
      newEventsSeen: number
    }
  }
}

export type ActionType = ActionTypes[keyof ActionTypes]

export const createUpdateProgressAction = (
  payload: ActionTypes['UpdateProgress']['payload'],
): ActionTypes['UpdateProgress'] => ({
  type: 'UPDATE_PROGRESS',
  payload,
})

export const createSetInitialStateAction = (
  payload: ActionTypes['SetInitialState']['payload'],
): ActionTypes['SetInitialState'] => ({
  type: 'SET_INITIAL_STATE',
  payload,
})

export const createSetInitialStateCountsAction = (
  payload: ActionTypes['SetInitialStateCounts']['payload'],
): ActionTypes['SetInitialStateCounts'] => ({
  type: 'SET_INITIAL_STATE_COUNTS',
  payload,
})
