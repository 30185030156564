import type { NetworkID } from 'common'
import type React from 'react'
import { NetworkIcon, type RFC, Td, clsx } from 'ui'

export const ContractWithAddress: RFC<{
  address: string
  network: NetworkID
  name: string
  component?: React.ComponentType | 'div'
}> = ({ className = undefined, children = undefined, address, network, name, component: Component = Td, ...props }) => {
  return (
    <Component className={clsx('space-y-2', className)} {...props}>
      <div className="font-medium">{name}</div>
      <div className="font-mono text-slate-500 text-sm">{address}</div>
    </Component>
  )
}

export const ContractWithAddressCell: RFC<{
  address: string
  network?: NetworkID
  name: string
}> = ({ className = undefined, children = undefined, address, network = undefined, name, ...props }) => {
  return (
    <Td className={clsx('space-y-2', className)} {...props}>
      <div className="flex space-x-sm items-center">
        <div className="font-medium">{name}</div>
        {network && <NetworkIcon network={network} size="sm" />}
      </div>
      <div className="font-mono text-slate-500 text-sm">{address}</div>
    </Td>
  )
}

export const EventWithSighashCell: RFC<{ name: string; sighash: string }> = ({
  className = undefined,
  children = undefined,
  name,
  sighash,
  ...props
}) => {
  return (
    <Td className={clsx('space-y-2', className)} {...props}>
      <div className="font-semibold font-mono tracking-tight">{name}</div>
      <div className="font-mono tracking-tight text-light text-sm line-clamp">{sighash}</div>
    </Td>
  )
}
