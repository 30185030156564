import clsx from 'clsx'
import React from 'react'
import { useMatches } from 'react-router-dom'
import { type BreadcrumbItemType, useToast } from 'ui'
import { useTypedOutletContext } from '../pages/outlet'

interface Props {
  className?: any
  children?: any
  title?: any
  titleText?: string
  description?: string
  action?: any
  fullWidth?: boolean
  breadcrumbs?: Array<BreadcrumbItemType>
  requiresProject?: boolean
}

export const Page = React.forwardRef<HTMLDivElement, Props & React.HTMLProps<HTMLDivElement>>(
  (
    {
      className,
      children,
      title = undefined,
      titleText = undefined,
      description = undefined,
      action = undefined,
      breadcrumbs = [],
      fullWidth = false,
      requiresProject = true,
      ...props
    },
    ref,
  ) => {
    const outletContext = useTypedOutletContext()
    const matches = useMatches()

    React.useEffect(() => {
      if (outletContext?.setBreadcrumbs) {
        const crumbs = matches
          .filter((m: any) => !(!m.pathname || !m.handle?.breadcrumb?.name))
          .map((match: any) => {
            return {
              name: match?.handle?.breadcrumb?.name || `No Name for ${match?.pathname}`,
              href: match?.pathname,
            }
          })
        outletContext.setBreadcrumbs(crumbs)
      }
    }, [outletContext?.setBreadcrumbs])

    React.useEffect(() => {
      if (title) {
        document.title = `${titleText || title} | Center`
      }
    }, [title])

    return (
      <div
        ref={ref}
        className={clsx(
          'mx-auto space-y-md',
          {
            'max-w-full': fullWidth,
          },
          className,
        )}
        {...props}
      >
        {title && (
          <div className="space-y-md">
            <div>
              <div className={clsx('flex w-full items-center justify-between', className)} {...props}>
                <h1 className="font-sans text-2xl">{title}</h1>
                <div>{action}</div>
              </div>
              {description && <p className="text-slate-500 text-sm mt-1">{description}</p>}
            </div>
          </div>
        )}
        {children}
      </div>
    )
  },
)
