import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import { RFC } from 'ui'
import { useChromaticScale } from 'common-web'

export function StackedHistogram<T extends { date: string }>({
  width,
  height,
  data = [],
  keys = [],
}: {
  width: number
  height: number
  data: Array<T>
  keys: Array<string & keyof T>
}) {
  const colors = useChromaticScale(keys)
  return (
    <BarChart width={width} height={height} data={data} margin={{ left: 20 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis
        tickFormatter={(value) => value.toLocaleString()}
        // label={{ value: 'Compute Units', angle: -90, position: 'insideLeft' }}
      />
      <Tooltip />
      <Legend />
      {keys.map((key) => (
        <Bar key={key} dataKey={key} stackId="a" fill={`${colors[key]}`} />
      ))}
    </BarChart>
  )
}
