import React from 'react'
import { allSourceTypes, type SourceTypeConfig } from 'common'
import { Link } from 'react-router-dom'
import { CardGrid, ItemCard, type RFC, SourceLogo } from 'ui'
import { Page } from '../../../components/page'
import { namedURLs } from '../../../lib/named-routes'

const SourceCard: RFC<{ option: SourceTypeConfig }> = ({ option }) => {
  const itemCardContent = (
    <ItemCard
      title={option.name}
      description={option.description}
      id={option.id}
      disabled={!option.available}
      icon={<SourceLogo variant="base" value={option.id} />}
      className="[&_.item-card-title]:!font-normal"
    />
  )

  return (
    <>
      {option.available ? (
        <Link to={namedURLs.sources.new({ sourceID: option.id })}>{itemCardContent}</Link>
      ) : (
        itemCardContent
      )}
    </>
  )
}

const SourcesGrid: RFC<{ items: Array<SourceTypeConfig> }> = ({ items = [] }) => {
  return (
    <CardGrid>
      {items.map((option) => (
        <SourceCard option={option} key={option.id} />
      ))}
    </CardGrid>
  )
}

function NewSourcePage() {
  const available = Object.values(allSourceTypes).filter((item) => item.available)
  const comingSoon = Object.values(allSourceTypes).filter((item) => !item.available)

  return (
    <Page title="Add New Source" className="space-y-8">
      <div className="space-y-4">
        <h3>Available</h3>
        <SourcesGrid items={available} />
      </div>
      <hr />
      <div className="space-y-4">
        <h3>Coming Soon</h3>
        <SourcesGrid items={comingSoon} />
      </div>
    </Page>
  )
}

export default NewSourcePage
