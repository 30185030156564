import { plans as commonPlans } from 'common'
import type { RFC } from 'common-web'
import type { PlanProps } from './'

const Highlight: RFC = ({ children }) => <span className="font-medium">{children}</span>

export const plansBySlug = {
  free: {
    ...commonPlans.free,
    sellingPoints: [
      <p key="compute-units">
        <Highlight>10K compute units</Highlight> / month
      </p>,
      <p key="rate">
        <Highlight>At least 20 compute units</Highlight> / sec
      </p>,
      <p key="support">
        <Highlight>Support team</Highlight> access
      </p>,
    ],
  },
  builder: {
    ...commonPlans.builder,
    sellingPoints: [
      <p key="compute-units">
        <Highlight>100K compute units</Highlight> / month
      </p>,
      <p key="rate">
        <Highlight>At least 50 compute units</Highlight> / sec
      </p>,
      <p key="team-mgmt">
        <Highlight>Team management</Highlight>
      </p>,
      <p key="support">
        <Highlight>Priority Support</Highlight>
      </p>,
    ],
  },
  enterprise: {
    ...commonPlans.enterprise,
    sellingPoints: [
      <p key="infinite">
        <Highlight>As many compute units</Highlight> as you need
      </p>,
      <p key="infinite">
        <Highlight>No rate limits</Highlight>
      </p>,
      <p key="infinite">
        <Highlight>Team Management</Highlight>
      </p>,
      <p key="infinite">
        <Highlight>Dedicated support channel</Highlight>
      </p>,
      <p key="infinite">
        <Highlight>Enterprise SLAs</Highlight> available
      </p>,
    ],
  },
} as const satisfies { [slug in PlanProps['slug']]: Omit<PlanProps, 'slug'> }

/**
 * An ordered list of the plans for display purposes
 */
export const plans: ReadonlyArray<PlanProps> = Object.entries(plansBySlug).map(([slug, planDetails]) => ({
  slug: slug as PlanProps['slug'],
  ...planDetails,
}))
