import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Page } from '../../components'
import { trpc } from '../../lib'

function useQueryParams() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function PostCheckoutPage() {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const stripeCheckoutSessionId = queryParams.get('stripe_session_id')
  console.log('Ended up on the post checkout page', { stripeCheckoutSessionId })
  const checkoutSessionResult = trpc.dashboard.billing.getCheckoutSessionResult.useQuery(
    { checkoutSessionId: stripeCheckoutSessionId! },
    { enabled: !!stripeCheckoutSessionId },
  )

  // For now we just always redirect the user to overview
  React.useEffect(() => {
    if (!stripeCheckoutSessionId) {
      navigate('/', { replace: true })
    }
  }, [])

  return <Page title="Checkout" description="Thanks for subscribing"></Page>

  // React.useEffect(() => {
  //   if (!stripeCheckoutSessionId) {
  //     navigate('/', { replace: true })
  //   }
  // }, [stripeCheckoutSessionId])

  // return (
  //   <Page title="Checkout" description="Thanks for subscribing">
  //     <div>{JSON.stringify(checkoutSessionResult, null, 4)}</div>
  //   </Page>
  // )
}
