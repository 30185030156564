import { useForm } from 'react-hook-form'
import { BetterForm, Form, Input } from 'ui'
import { Page } from '../../../../components'

export const NewEvmEventLogPage = () => {
  const form = useForm({
    defaultValues: {
      abiFragment: {
        name: 'Transfer(uint256,uint256,uint256)',
      },
    },
  })

  const handleSubmit = (values: Record<string, never>) => {}

  return (
    <Page title="New EVM Event Log">
      <BetterForm.Provider form={form} onSubmit={handleSubmit}>
        <BetterForm.Field name="abiFragment.name" />
      </BetterForm.Provider>
    </Page>
  )
}
