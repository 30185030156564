import type React from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

interface Segment {
  value: number
  id: string
  label: string
  color: string
}

interface StackedProgressBarProps {
  values: Segment[]
  height?: string
  maxValue: number
}

export const StackedProgressBar: React.FC<StackedProgressBarProps> = ({ values = [], height = '20px', maxValue }) => {
  // No need to calculate totalValue based on segments, as we will use maxValue directly
  return (
    <>
      <div
        style={{
          display: 'flex',
          height,
          backgroundColor: '#e0e0e0',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        {values.map((segment, index) => (
          <div
            key={index}
            data-tooltip-id={`tooltip-${segment.id}`}
            style={{
              flex: segment.value / maxValue, // Use maxValue for calculating the width of each segment
              backgroundColor: segment.color,
              transition: 'flex 0.3s ease',
            }}
          />
        ))}
      </div>
      {values.map((segment, index) => (
        <Tooltip id={`tooltip-${segment.id}`} key={index}>
          {`${segment.label}: ${segment.value}`}
        </Tooltip>
      ))}
    </>
  )
}
