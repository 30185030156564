import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import type { RFC } from 'ui'
import { getStripe } from '../lib/stripe'
import { trpc } from '../lib/trpc'

type IStripeCheckoutProps = {
  projectId: string
  returnUrl: string
  planSlug: 'free' | 'builder'
}

export const StripeCheckout: RFC<IStripeCheckoutProps> = function StripeCheckout({ projectId, returnUrl, planSlug }) {
  const stripe = getStripe()
  const stripeCheckoutQuery = trpc.dashboard.billing.createCheckoutSession.useQuery({
    projectId,
    returnUrl,
    planSlug,
  })

  if (!stripeCheckoutQuery.data) {
    return <>Loading....</>
  }

  const { clientSecret } = stripeCheckoutQuery.data

  return (
    <>
      <p className="text-slate-700 text-3xl font-bold">Plan</p>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </>
  )
}
