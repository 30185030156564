import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import { Toaster, useToast } from 'ui'
import { useCenterTrpcClient } from 'common-web'
import { trpc } from './lib/trpc'
import { router } from './router'
import { trpcClient } from './trpc-client'

export const DashboardApp = () => {
  const { error: onError } = useToast()
  const queryClient = useCenterTrpcClient({ onError })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </trpc.Provider>
  )
}
