import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Debug, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'ui'
import { ProjectContext } from '../contexts/project-context'
import { cn } from '../lib/utils'

export function ProjectSwitcher({ ...props }) {
  const { setProjectId, project, projects } = React.useContext(ProjectContext)
  const navigate = useNavigate()

  const handleSelectProject = React.useCallback(
    (val: string) => {
      if (val === 'new') {
        navigate('/projects/new')
      } else {
        if (!!val && setProjectId) {
          setProjectId(val)
          navigate('/')
        }
      }
    },
    [setProjectId, navigate],
  )
  return (
    <Select value={project?.id} onValueChange={handleSelectProject} {...props}>
      <SelectTrigger
        className={cn(
          'flex bg-white items-center gap-1 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 w-full min-w-[12rem]',
          // isCollapsed &&
          // 'flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden'
        )}
        aria-label="Select account"
        title={project?.name}
      >
        <SelectValue placeholder="Select a project">
          {/* {projects.find((account) => account.id === project?.id)?.icon} */}
          <span className={cn('ml-0 text-sm select-none')}>{project?.name}</span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {projects.map((proj) => (
          <SelectItem key={proj.id} value={proj.id}>
            <div className="flex items-center gap-2 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              <div />
              <div className="w-full text-sm text-ellipsis whitespace-nowrap overflow-hidden" title={proj.name}>
                {proj.name}
              </div>
            </div>
          </SelectItem>
        ))}
        <div className="py-1">
          <hr className="border-t border-gray-200" />
        </div>
        <SelectItem key={'new'} value={'new'}>
          <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
            Create New Project
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  )
}
