import React from 'react'
import { useParams } from 'react-router-dom'
import { clsx, Orb, type RFC, Row, Spinner, useSimpleTable } from 'ui'
import { Page } from '../../../../../../components'
import { useProject } from '../../../../../../contexts'
import { trpc } from '../../../../../../lib/trpc'
import { EventLogTable } from './event-log-table'
import { NewEventsCard } from './new-events-card'
import { useEventLogs } from './redux'

const ShowSighashPage: RFC<{}> = ({ className = undefined, children = undefined, ...props }) => {
  const project = useProject()
  const { id, fragmentId } = useParams()
  if (!id || !fragmentId) {
    throw new Error('No id or fragmentId')
  }

  const { state, actions } = useEventLogs({
    contractID: id,
    eventID: fragmentId,
    projectID: project.id,
  })

  const { data: eventLog } = trpc.abiFragments.show.useQuery(
    {
      contractID: id,
      eventID: fragmentId,
    },
    {
      enabled: id && fragmentId && project?.id ? true : false,
      refetchOnMount: true,
    },
  )

  const { data: evmContract } = trpc.evmContractsV1.show.useQuery(
    {
      contractId: id,
      project: {
        id: project?.id,
      },
    },
    { enabled: id && project?.id ? true : false },
  )

  return (
    <Page
      title={<span className="font-mono font-bold">{eventLog?.prettyFragmentName}</span>}
      titleText={eventLog?.prettyFragmentName}
      className={clsx(className, 'h-full')}
      fullWidth
      {...props}
    >
      <NewEventsCard
        countNewEvents={state.newEventsSeen}
        currentTip={state.head}
        isLoading={state.isLoadingHead}
        onClick={() => actions.refreshItems()}
        className={clsx('transition-all', {})}
      />
      <EventLogTable
        isLoadingHead={state.isLoadingHead}
        items={state.items}
        params={eventLog?.fragmentClaim.abiFragment.params || []}
      />
    </Page>
  )
}

export default ShowSighashPage
