'use client'

import React from 'react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as SelectPrimitive from '@radix-ui/react-select'
import { CheckIcon, ChevronDown } from 'lucide-react'
import { cn } from '../lib'

export type SelectProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root>

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

interface SelectTriggerProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  leadingIcon?: React.ReactNode
}

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
  ({ className, children, leadingIcon, ...props }, ref) => (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        'border-input border-gray-300 ring-offset-background placeholder:text-muted-foreground !flex gap-3 h-10 w-full items-center justify-between rounded-lg border bg-transparent py-2 text-base text-gray-900 shadow-sm focus:outline-none focus:ring-4 focus:ring-ring focus-visible:border-brand-primary focus-visible:ring-brand-focus data-[state=open]:ring-4 data-[state=open]:ring-brand-focus data-[state=open]:border-brand-primary disabled:cursor-not-allowed disabled:opacity-50 data-[placeholder]:line-clamp-1 [&>span]:line-clamp-1 data-[placeholder]:text-gray-500 text-left',
        leadingIcon ? 'relative [&>span]:ml-7' : '',
        className,
      )}
      {...props}
    >
      {leadingIcon && <div className="absolute [&>svg]:w-5 [&>svg]:h-auto">{leadingIcon}</div>}
      {children}
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="h-5 w-5 stroke-gray-500" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  ),
)
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'bg-white text-gray-900 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 min-w-[8rem] rounded-md border shadow-[0_4px_6px_-2px_rgba(16,24,40,0.03),0px_12px_16px_-4px_rgba(16,24,40,0.08)]',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}
    >
      <ScrollArea.Root className="ScrollAreaRoot" type="auto">
        <SelectPrimitive.Viewport
          className={cn(
            'p-1',
            position === 'popper' &&
              'max-h-[20rem] max-h-[var(--radix-select-content-available-height)] w-full w-[var(--radix-select-trigger-width)]',
          )}
        >
          <ScrollArea.Viewport className="ScrollAreaViewport">{children}</ScrollArea.Viewport>
        </SelectPrimitive.Viewport>
        <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
          <ScrollArea.Thumb className="ScrollAreaThumb" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('px-2 py-1.5 text-sm font-semibold', className)} {...props} />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'focus:bg-gray-50 focus:text-slate-900 relative flex cursor-default select-none items-center rounded-md py-[.63rem] px-2 mx-[.38rem] my-[.13rem] text-base leading-6 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[state=checked]:bg-gray-50 data-[state=checked]:font-medium',
        className,
      )}
      {...props}
    >
      <span className="absolute right-4 flex items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <CheckIcon className="stroke-brand-primary h-5 w-5" />
        </SelectPrimitive.ItemIndicator>
      </span>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  )
})

SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('bg-muted -mx-1 my-1 h-px', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

interface SelectFieldGroupProps {
  label?: string
  helpText?: string
  children: React.ReactNode
}

const SelectFieldGroup = ({ label, helpText, children, ...props }: SelectFieldGroupProps) => {
  return (
    <div {...props}>
      {label && <label className="text-sm text-gray-700 leading-5 font-medium mb-xxs block">{label}</label>}
      {children}
      {helpText && <p className="text-sm text-gray-600 leading-5 mt-2">{helpText}</p>}
    </div>
  )
}

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectFieldGroup,
}
