import { Page, useProjectId } from '@/components'
import { useProject } from '@/contexts'
import { type BFC, trpc } from '@/lib'
import { Tab, TabGroup, TabList, TabListProps, TabPanel, TabPanels } from '@headlessui/react'
import { MoreVerticalIcon } from 'lucide-react'
import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  CardWithContent,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  TabNav,
  useToast,
} from 'ui'
import { AbiFragmentCoveragesTable } from './components/abi-fragment-coverages-table'
import { AddRelatedContractTab } from './components/add-related-contract-tab'
import { ContractInfoTable } from './components/contract-info-table'
import { useDeleteContract, useEVMContractDetail } from './components/hooks'
import { RelatedContractsTab } from './components/related-contracts-tab'

const tabs = [
  { label: 'Contract Events', id: 'events' },
  // { label: 'ABI', id: 'abi' },
  { label: 'Related Contracts', id: 'related' },
  { label: 'Add Related Contract', id: 'add-related' },
  // { label: 'Scan for Proxies', id: 'proxies' },
] as const

const ShowEVMContractPage: BFC = ({ className = undefined, children = undefined, ...props }) => {
  const params = useParams()
  const project = useProject()
  const projectId = useProjectId()
  const navigate = useNavigate()
  const { toast } = useToast()
  const { handleDelete } = useDeleteContract(project, params, toast, navigate)
  const location = useLocation()

  const { data: destinations } = trpc.destinations.list.useQuery(
    {
      projectId,
    },
    {
      placeholderData: [],
      select(data) {
        return data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      },
    },
  )

  const { evmContract, abiFragmentCoverages, isLoading } = useEVMContractDetail({
    contractId: params.id,
    projectId: project?.id,
  })

  const currentTab = new URLSearchParams(location.search).get('tab') || tabs[0].id
  const selectedIndex = useMemo(() => tabs.findIndex((tab) => tab.id === currentTab), [currentTab])

  const handleTabChange = (tabId: string) => {
    navigate({
      pathname: location.pathname,
      search: `?tab=${tabId}`,
    })
  }

  return (
    <Page
      fullWidth
      title={evmContract?.name || ' '}
      action={
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <MoreVerticalIcon />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem disabled>Edit</DropdownMenuItem>
            <DropdownMenuItem onClick={handleDelete}>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      }
      {...props}
    >
      <ContractInfoTable data={evmContract} />

      <Tab.Group selectedIndex={selectedIndex} onChange={(index) => handleTabChange(tabs[index].id)}>
        <div className="-mx-lg">
          <Tab.List as={TabNav.Menu} className="px-lg">
            {tabs.map((tab, index) => (
              <Tab
                as={TabNav.Tab}
                selected={selectedIndex === index}
                key={tab.id}
                className="focus:outline-none text-sm"
              >
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel id="events" className={'px-lg py-md'}>
              <AbiFragmentCoveragesTable
                isLoading={isLoading}
                // @ts-ignore for now
                items={abiFragmentCoverages}
                params={params}
              />
            </Tab.Panel>
            <Tab.Panel id="abi" className={'px-xl py-lg'}>
              <RelatedContractsTab contractId={params.id!} />
            </Tab.Panel>
            {/* <Tab.Panel id="implementations" className={'px-xl py-lg'}></Tab.Panel> */}
            <Tab.Panel id="related" className={'px-xl py-lg'}>
              {params.id && evmContract?.network && (
                <AddRelatedContractTab contractId={params.id} network={evmContract?.network} />
              )}
            </Tab.Panel>
            {/* <Tab.Panel id="proxies" className={'px-xl py-lg'}>
              Scan for proxies
            </Tab.Panel> */}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </Page>
  )
}

export default ShowEVMContractPage
