import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BetterForm, ButtonWithSpinner, useToast } from 'ui'
import { Page } from '../../../components'
import { useProject } from '../../../contexts'
import { type RouterInputs, trpc } from '../../../lib'

const NewApiKeyPage = () => {
  const navigate = useNavigate()
  const { id: projectId } = useProject()
  const createApiKey = trpc.dashboard.apiKeys.create.useMutation()
  const { success, notify } = useToast()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onSubmit = async ({ name }: Pick<RouterInputs['dashboard']['apiKeys']['create'], 'name'>) => {
    try {
      setIsSubmitting(true)
      if (!projectId) {
        notify({
          title: 'Project Required',
          message: 'Please select a project before performing this operation',
        })
        return
      }
      const data = await createApiKey.mutateAsync({
        projectId,
        name,
      })
      if (data) {
        navigate('..')
        success({ title: 'API Key Created' })
      }
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const form = useForm({
    defaultValues: {},
  })

  return (
    <Page title="New API Key">
      <BetterForm.Provider form={form} onSubmit={onSubmit}>
        <BetterForm.Field autoFocus={true} label="API Key Name" name="name" />
        <ButtonWithSpinner isLoading={isSubmitting}>Create API Key</ButtonWithSpinner>
      </BetterForm.Provider>
    </Page>
  )
}

export default NewApiKeyPage
