import { EventWithSighashCell, SyncStatusBadge } from '@/components'
import { namedURLs } from '@/lib'
import { SourceTypeID } from 'common'
import type { AbiFragmentIndexingProgressType } from 'common/src/chainpipe/redux'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { Progress, SpinnerWithLabel, TBody, THead, Table, Td, Th, Tr } from 'ui'

export const AbiFragmentCoveragesTable = ({
  items,
  params,
  isLoading,
}: {
  isLoading: boolean
  items: Array<AbiFragmentIndexingProgressType>
  params: Record<string, string | undefined>
}) => {
  const navigate = useNavigate()
  const { id: abiFragmentId } = params
  if (!abiFragmentId) {
    throw new Error('Expected abiFragmentId')
  }

  return (
    <Table>
      <THead>
        <Tr>
          <Th>Name</Th>
          <Th right>Events Seen</Th>
          <Th>Sync Status</Th>
        </Tr>
      </THead>
      {isLoading && (
        <TBody>
          <Tr>
            <Td colSpan={4}>
              <SpinnerWithLabel>
                <div>Loading</div>
              </SpinnerWithLabel>
            </Td>
          </Tr>
        </TBody>
      )}
      <TBody>
        {(items || []).map((obj) => (
          <Tr
            key={obj.sighash}
            onClick={() =>
              navigate(
                namedURLs.sources.showABIFragmentDetail({
                  sourceID: SourceTypeID.EVM_CONTRACT,
                  id: abiFragmentId,
                  claimID: obj.id,
                }),
              )
            }
            className="cursor-pointer"
          >
            <EventWithSighashCell name={obj.fullName} sighash={obj.sighash} />
            <Td right>
              <NumberFormat thousandSeparator value={obj.totalCount} displayType="text" />
            </Td>
            <Td className="w-[25%]">
              <div className="space-y-2">
                <Progress value={obj.progressRatio * 100} className="col-span-3" />
                <SyncStatusBadge ratio={obj.progressRatio} status={obj?.active ? 'indexing' : 'done'} />
              </div>
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  )
}
