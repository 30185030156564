import React from 'react'
import { useParams } from 'react-router-dom'
import { CardWithContent, CopyIcon, Debug, type RFC, useCopyToClipboard, useToast } from 'ui'
import { Page, useProjectId } from '../../../../components'
import { trpc } from '../../../../lib'
import { RunMigrationsWidget } from './run-migrations-widget'

export const ShowPostgresGatewayPage = () => {
  const projectId = useProjectId()
  const { id } = useParams()

  const { data: gatewayData } = trpc.destinations.managedPostgresDatabases.show.useQuery(
    {
      id: id!,
      projectId,
    },
    {
      enabled: !!id,
    },
  )

  return (
    <Page title={`Postgres Managed DB: ${gatewayData?.name}`}>
      <CardWithContent title="Database URL" description="Click to copy to clipboard">
        {(gatewayData?.credentials || []).map((credential) => (
          <ClickToCopyUrl value={credential.databaseUrl} />
        ))}
      </CardWithContent>

      {id && <RunMigrationsWidget id={id} projectId={projectId} />}
    </Page>
  )
}

const ClickToCopyUrl: RFC<{ value: string }> = ({ value }) => {
  const { copied, copy } = useCopyToClipboard(value || '')
  const { success } = useToast()
  const handleCopy = React.useCallback(() => {
    copy()
    success({ title: 'Database URL copied to clipboard' })
  }, [copy])

  return (
    <div
      className="flex subpixel-antialiased w-full justify-between items-center hover:bg-gray-50 active:shadow-inner transition-all cursor-pointer font-ibm rounded-lg border px-4 py-2 border-gray-200"
      onClick={handleCopy}
    >
      <div className="overflow-ellipsis select-none">{value}</div>
      <CopyIcon />
    </div>
  )
}
