import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { BetterForm, Button, Card, CardGrid, clsx, Spinner } from 'ui'
import { ButtonLink, Page } from '../../components'
import { ProjectContext, useNewProjectCreation } from '../../contexts/project-context'
import { trpc, type RouterInputs } from '../../lib'

export const ProjectsIndexPage = () => {
  const { projects } = useContext(ProjectContext)
  return (
    <Page
      title="Projects"
      description="Projects are how you organize resources"
      action={<ButtonLink to="/projects/new">New Project</ButtonLink>}
      requiresProject={false}
    >
      <CardGrid>
        {projects.map((project) => (
          <Card>{project.name}</Card>
        ))}
      </CardGrid>
    </Page>
  )
}

type ICreateProjectInputs = RouterInputs['dashboard']['projects']['create']
export const NewProjectPage = () => {
  const form = useForm({})
  const createProject = trpc.dashboard.projects.create.useMutation()
  const { handleProjectCreatedSuccess, handleProjectCreatedFailure } = useNewProjectCreation()

  const onSubmit = React.useCallback((values: ICreateProjectInputs) => {
    return createProject.mutateAsync(values).then(handleProjectCreatedSuccess).catch(handleProjectCreatedFailure)
  }, [])

  return (
    <Page title="Create a Project" description="Use projects to organize resources" requiresProject={false}>
      <BetterForm.Provider onSubmit={onSubmit} form={form}>
        <BetterForm.Field
          name="project.name"
          description="Something helpful"
          label={'Project Name'}
          placeholder="Production Website"
          autoFocus
          required={true}
        />

        <Button type="submit" disabled={createProject.isLoading} className="relative">
          <Spinner
            className={clsx({
              'opacity-100': createProject.isLoading,
              'opacity-0': !createProject.isLoading,
              absolute: true,
            })}
          />
          <div
            className={clsx({
              'opacity-100': !createProject.isLoading,
              'opacity-0': createProject.isLoading,
            })}
          >
            Create Project
          </div>
        </Button>
      </BetterForm.Provider>
    </Page>
  )
}
