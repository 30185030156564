import { useOutletContext } from 'react-router-dom'
import type { BreadcrumbItemType } from 'ui'

export interface OutletContextType {
  title: string
  setTitle: (title: string) => void
  breadcrumbs: Array<BreadcrumbItemType>
  setBreadcrumbs: (items: Array<BreadcrumbItemType>) => void
}

export function useTypedOutletContext() {
  return useOutletContext<OutletContextType>()
}
