import clsx from 'clsx'
import type { RFC } from 'common-web'
import { Column, Svg, type SvgProps, twJoin } from '..'

export const CenterLogo: RFC<SvgProps> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 134 170" fill="none" className={clsx('h-full', className)} {...props}>
      <path
        d="M71.087 50.072L100.769 79.7814C101.457 80.2821 102.301 80.5212 103.149 80.4557C103.998 80.3901 104.794 80.0243 105.397 79.4239L132.543 52.2745C132.876 51.9427 133.14 51.5484 133.32 51.1143C133.5 50.6802 133.593 50.2148 133.593 49.7448C133.593 49.2748 133.5 48.8093 133.32 48.3752C133.14 47.9411 132.876 47.5469 132.543 47.215L94.7385 9.41038C88.8782 3.55247 80.9314 0.261719 72.6453 0.261719C64.3593 0.261719 56.4125 3.55247 50.5521 9.41038L28.1855 31.7734C42.8379 18.8919 46.1537 25.1387 71.087 50.072Z"
        fill="#14E7DE"
      />
      <path
        d="M39.2022 84.8395L41.9006 87.5379C41.5677 87.2061 41.3035 86.8118 41.1233 86.3777C40.943 85.9436 40.8505 85.4782 40.8505 85.0081C40.8505 84.5381 40.943 84.0727 41.1233 83.6386C41.3035 83.2045 41.5677 82.8102 41.9006 82.4784L70.1394 54.2395C70.4712 53.9067 70.8654 53.6425 71.2995 53.4623C71.7336 53.2821 72.1991 53.1894 72.6691 53.1894C73.1391 53.1894 73.6046 53.2821 74.0387 53.4623C74.4728 53.6425 74.867 53.9067 75.1989 54.2395L100.375 79.419C100.508 79.5475 100.651 79.667 100.8 79.7766L71.1177 50.0671C46.1844 25.1339 42.8619 18.8871 28.2264 31.7787L18.4177 41.5874C6.57846 55.9025 16.34 61.9739 39.2022 84.8395Z"
        fill="#0085FF"
      />
      <path
        d="M70.7334 116.254L72.8481 118.301C98.004 143.063 114.538 140.435 132.395 122.855L132.732 122.518C133.213 121.832 133.437 121 133.365 120.166C133.294 119.333 132.931 118.551 132.341 117.957C126.924 112.506 113.931 99.4361 105.249 90.7371C104.58 90.0699 103.673 89.6953 102.728 89.6953C101.783 89.6953 100.876 90.0699 100.207 90.7371L75.1891 115.758C74.6133 116.328 73.8601 116.685 73.054 116.77C72.9241 116.784 72.7934 116.792 72.6627 116.794C71.9811 116.802 71.3115 116.615 70.7334 116.254Z"
        fill="#0085FF"
      />
      <path
        d="M132.741 122.533L132.404 122.87C114.547 140.451 97.9993 143.078 72.8569 118.317L70.7419 116.269C70.6251 116.198 70.5125 116.121 70.4046 116.037C70.3102 115.959 70.2192 115.878 70.1315 115.79L67.1531 112.812L41.8928 87.5516L39.1944 84.8532C16.3322 61.991 6.57054 55.9297 18.3862 41.6079L5.23145 54.7626C3.88387 56.1089 2.76215 57.6636 1.90917 59.3668C1.74052 59.7041 1.5719 60.0414 1.43023 60.3787C1.25484 60.7902 1.09288 61.2118 0.954587 61.6335C0.324863 63.4797 0.00236452 65.4168 0 67.3676V102.629C0.00208391 107.365 1.88356 111.907 5.23145 115.258L50.5612 160.587C52.0561 162.083 53.6982 163.425 55.4622 164.591C56.5455 165.3 57.672 165.942 58.8353 166.51C59.2603 166.72 59.6888 166.918 60.1206 167.107C60.8491 167.445 61.5877 167.728 62.3331 167.988C62.9402 168.2 63.5509 168.386 64.1682 168.561C64.9012 168.768 65.6386 168.947 66.3807 169.097L66.917 169.205C68.8024 169.558 70.7162 169.736 72.6343 169.738H72.6715C75.6839 169.738 78.6805 169.304 81.5695 168.45C82.0518 168.308 82.5306 168.153 83.0096 167.984C83.9652 167.652 84.9053 167.27 85.8295 166.841C87.2329 166.192 88.5859 165.438 89.8771 164.588C90.7503 164.012 91.5936 163.391 92.4036 162.729C93.2264 162.055 94.0144 161.339 94.7647 160.584L132.347 123.002C132.491 122.857 132.623 122.7 132.741 122.533Z"
        fill="#293EFF"
      />
    </Svg>
  )
}

export const CenterLogoAndWordmark: RFC<{
  className?: string
}> = ({ ...props }) => (
  <Svg viewBox="0 0 650 170" fill="none" {...props}>
    <path
      d="M333.444 54.5752C355.36 54.5752 371.258 68.0038 371.258 91.6193C371.258 95.0141 370.95 98.7197 370.333 101.498H313.841V105.973C313.841 117.241 322.022 124.649 333.753 124.649C343.476 124.649 350.576 119.868 352.274 112.457H371.258C368.48 128.819 353.51 139.622 333.906 139.622C309.674 139.622 294.084 122.952 294.084 97.0223C294.084 70.1746 310.29 54.5752 333.444 54.5752ZM351.657 88.6859V86.0561C351.657 76.3307 344.246 69.5411 333.289 69.5411C322.022 69.5411 313.841 76.4741 313.841 86.6729V88.6787L351.657 88.6859Z"
      fill="#040404"
    />
    <path
      d="M377.122 56.1196H394.254L396.724 71.0903H400.274C404.905 60.9034 415.097 54.5752 427.748 54.5752C444.11 54.5752 454.142 64.6162 454.142 82.2047V138.071H434.538V88.8222C434.538 76.9379 428.982 70.6097 417.867 70.6097C404.594 70.6097 396.722 80.6507 396.722 94.6866V138.071H377.118L377.122 56.1196Z"
      fill="#040404"
    />
    <path
      d="M511.407 122.335V138.071H486.558C474.672 138.071 470.66 131.743 470.66 122.481V72.1709H455.223V56.1196H470.66V29.8791H490.106V56.1196H509.71V72.1709H490.106V122.335H511.407Z"
      fill="#040404"
    />
    <path
      d="M547.368 54.5752C569.286 54.5752 585.184 68.0038 585.184 91.6193C585.184 95.0141 584.876 98.7197 584.256 101.498H527.766V105.973C527.766 117.241 535.945 124.649 547.676 124.649C557.401 124.649 564.499 119.868 566.199 112.457H585.184C582.406 128.819 567.433 139.622 547.831 139.622C523.597 139.622 508.009 122.952 508.009 97.0223C508.009 70.1746 524.216 54.5752 547.368 54.5752ZM565.58 88.6859V86.0561C565.58 76.3307 558.169 69.5411 547.212 69.5411C535.945 69.5411 527.766 76.4741 527.766 86.6729V88.6787L565.58 88.6859Z"
      fill="#040404"
    />
    <path
      d="M650 56.1191V74.1786C648.303 74.0232 646.297 73.8678 644.599 73.8678C629.935 73.8678 618.514 82.2042 618.514 100.108V138.07H598.91V72.1704H584.401V56.1191H616.045L618.514 73.0932H622.065C625.304 61.5173 634.102 55.641 644.444 55.641C646.45 55.6553 648.766 55.8107 650 56.1191Z"
      fill="#040404"
    />
    <path
      d="M247.522 33.6373C272.531 33.6373 290.758 48.333 292.453 70.7985H272.813C270.836 58.0823 260.803 50.4512 246.814 50.4512C229.154 50.4512 219.12 61.3313 219.12 78.8528V94.818C219.12 111.773 229.436 122.653 246.673 122.653C260.238 122.653 270.411 114.883 272.813 102.165H292.453C289.909 125.479 271.401 139.46 246.391 139.46C215.312 139.46 199.055 118.407 199.055 86.7565C199.058 54.4078 216.012 33.6373 247.522 33.6373Z"
      fill="#040404"
    />
    <path
      d="M71.087 49.8102L100.769 79.5197C101.457 80.0204 102.301 80.2595 103.149 80.1939C103.998 80.1284 104.794 79.7626 105.397 79.1621L132.543 52.0128C132.876 51.681 133.14 51.2867 133.32 50.8526C133.5 50.4185 133.593 49.9531 133.593 49.4831C133.593 49.013 133.5 48.5476 133.32 48.1135C133.14 47.6794 132.876 47.2851 132.543 46.9533L94.7385 9.14866C88.8782 3.29075 80.9314 0 72.6453 0C64.3593 0 56.4125 3.29075 50.5521 9.14866L28.1855 31.5117C42.8379 18.6302 46.1537 24.877 71.087 49.8102Z"
      fill="#14E7DE"
    />
    <path
      d="M39.2023 84.5777L41.9008 87.2761C41.5679 86.9443 41.3036 86.55 41.1234 86.1159C40.9432 85.6818 40.8506 85.2164 40.8506 84.7464C40.8506 84.2764 40.9432 83.811 41.1234 83.3768C41.3036 82.9427 41.5679 82.5485 41.9008 82.2166L70.1395 53.9778C70.4713 53.6449 70.8655 53.3808 71.2996 53.2006C71.7337 53.0204 72.1992 52.9276 72.6692 52.9276C73.1393 52.9276 73.6047 53.0204 74.0389 53.2006C74.473 53.3808 74.8671 53.6449 75.199 53.9778L100.375 79.1573C100.509 79.2857 100.651 79.4052 100.8 79.5149L71.1178 49.8054C46.1845 24.8721 42.862 18.6253 28.2266 31.5169L18.4178 41.3257C6.57858 55.6407 16.3401 61.7121 39.2023 84.5777Z"
      fill="#0085FF"
    />
    <path
      d="M70.7334 115.992L72.8481 118.04C98.004 142.801 114.538 140.173 132.395 122.593L132.732 122.256C133.213 121.571 133.437 120.738 133.365 119.905C133.294 119.071 132.931 118.289 132.341 117.695C126.924 112.245 113.931 99.1743 105.249 90.4753C104.58 89.8082 103.673 89.4336 102.728 89.4336C101.783 89.4336 100.876 89.8082 100.207 90.4753L75.1891 115.496C74.6133 116.067 73.8601 116.424 73.054 116.508C72.9241 116.523 72.7934 116.531 72.6627 116.532C71.9811 116.541 71.3115 116.353 70.7334 115.992Z"
      fill="#0085FF"
    />
    <path
      d="M132.741 122.271L132.404 122.609C114.547 140.189 97.9993 142.816 72.8569 118.055L70.7419 116.008C70.6251 115.937 70.5125 115.859 70.4046 115.775C70.3102 115.697 70.2192 115.616 70.1315 115.529L67.1531 112.55L41.8928 87.2899L39.1944 84.5915C16.3322 61.7292 6.57054 55.6679 18.3862 41.3462L5.23145 54.5009C3.88387 55.8472 2.76215 57.4018 1.90917 59.1051C1.74052 59.4424 1.5719 59.7797 1.43023 60.117C1.25484 60.5285 1.09288 60.9501 0.954587 61.3717C0.324863 63.218 0.00236452 65.1551 0 67.1058V102.367C0.00208391 107.104 1.88356 111.645 5.23145 114.996L50.5612 160.326C52.0561 161.822 53.6982 163.163 55.4622 164.329C56.5455 165.039 57.672 165.68 58.8353 166.249C59.2603 166.458 59.6888 166.657 60.1206 166.846C60.8491 167.183 61.5877 167.466 62.3331 167.726C62.9402 167.939 63.5509 168.124 64.1682 168.299C64.9012 168.506 65.6386 168.685 66.3807 168.836L66.917 168.944C68.8024 169.296 70.7162 169.474 72.6343 169.477H72.6715C75.6839 169.476 78.6805 169.042 81.5695 168.188C82.0518 168.046 82.5306 167.891 83.0096 167.723C83.9652 167.39 84.9053 167.009 85.8295 166.579C87.2329 165.93 88.5859 165.177 89.8771 164.326C90.7503 163.75 91.5936 163.13 92.4036 162.467C93.2264 161.793 94.0144 161.077 94.7647 160.322L132.347 122.74C132.491 122.595 132.623 122.439 132.741 122.271Z"
      fill="#293EFF"
    />
  </Svg>
)

export const CenterWordmark: RFC<SvgProps> = ({ className, ...props }) => {
  return (
    <Svg className={twJoin(className)} viewBox="0 0 451 110" fill="none" {...props}>
      <path
        d="M134.444 24.8369C156.36 24.8369 172.258 38.2655 172.258 61.881C172.258 65.2758 171.95 68.9814 171.333 71.7594H114.841V76.2348C114.841 87.5023 123.022 94.9111 134.753 94.9111C144.476 94.9111 151.576 90.1297 153.274 82.7185H172.258C169.48 99.0805 154.51 109.884 134.906 109.884C110.674 109.884 95.0837 93.2137 95.0837 67.284C95.0837 40.4363 111.29 24.8369 134.444 24.8369ZM152.657 58.9476V56.3178C152.657 46.5924 145.246 39.8028 134.289 39.8028C123.022 39.8028 114.841 46.7358 114.841 56.9346V58.9404L152.657 58.9476Z"
        fill="#040404"
      />
      <path
        d="M178.122 26.3813H195.254L197.724 41.352H201.274C205.905 31.1651 216.097 24.8369 228.748 24.8369C245.11 24.8369 255.142 34.8779 255.142 52.4664V108.333H235.538V59.0839C235.538 47.1996 229.982 40.8714 218.867 40.8714C205.594 40.8714 197.722 50.9124 197.722 64.9483V108.333H178.118L178.122 26.3813Z"
        fill="#040404"
      />
      <path
        d="M312.407 92.5969V108.333H287.558C275.672 108.333 271.66 102.004 271.66 92.7428V42.4326H256.223V26.3813H271.66V0.140869H291.106V26.3813H310.71V42.4326H291.106V92.5969H312.407Z"
        fill="#040404"
      />
      <path
        d="M348.368 24.8369C370.286 24.8369 386.184 38.2655 386.184 61.881C386.184 65.2758 385.876 68.9814 385.256 71.7594H328.766V76.2348C328.766 87.5023 336.945 94.9111 348.676 94.9111C358.401 94.9111 365.499 90.1297 367.199 82.7185H386.184C383.406 99.0805 368.433 109.884 348.831 109.884C324.597 109.884 309.009 93.2137 309.009 67.284C309.009 40.4363 325.216 24.8369 348.368 24.8369ZM366.58 58.9476V56.3178C366.58 46.5924 359.169 39.8028 348.212 39.8028C336.945 39.8028 328.766 46.7358 328.766 56.9346V58.9404L366.58 58.9476Z"
        fill="#040404"
      />
      <path
        d="M451 26.3809V44.4403C449.303 44.2849 447.297 44.1295 445.599 44.1295C430.935 44.1295 419.514 52.4659 419.514 70.37V108.332H399.91V42.4321H385.401V26.3809H417.045L419.514 43.3549H423.065C426.304 31.7791 435.102 25.9027 445.444 25.9027C447.45 25.9171 449.766 26.0725 451 26.3809Z"
        fill="#040404"
      />
      <path
        d="M48.5221 3.89899C73.5313 3.89899 91.7581 18.5947 93.4531 41.0603H73.8135C71.8363 28.3441 61.8025 20.7129 47.8144 20.7129C30.1543 20.7129 20.1205 31.593 20.1205 49.1146V65.0798C20.1205 82.0347 30.4364 92.9148 47.6734 92.9148C61.2383 92.9148 71.4108 85.145 73.8135 72.4264H93.4531C90.9094 95.7406 72.4005 109.722 47.3913 109.722C16.312 109.722 0.0551758 88.6689 0.0551758 57.0183C0.0575665 24.6695 17.0125 3.89899 48.5221 3.89899Z"
        fill="#040404"
      />
    </Svg>
  )
}
