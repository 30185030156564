import React from 'react'
import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, clsx, DestinationLogo, RFC, Row, Table, TBody, Td, Th, THead, Tr } from 'ui'
import { Page } from '../../../components/page'
import { useProjectId } from '../../../contexts'
import { namedURLs } from '../../../lib/named-routes'
import { trpc } from '../../../lib/trpc'

const ListDestinationsPage = () => {
  const projectID = useProjectId()
  const navigate = useNavigate()

  const { data: listDestinations } = trpc.destinations.list.useQuery({
    projectId: projectID,
  })

  const handleClickCreate = React.useCallback(() => {
    navigate(namedURLs.destinations.add())
  }, [navigate])

  return (
    <Page
      title="Destinations"
      action={
        <Button onClick={handleClickCreate} leftIcon={<Plus className="w-4 h-4" />}>
          Add Destination
        </Button>
      }
    >
      <Row>
        <Table>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Connection Type</Th>
              {/* <Th>Status</Th> */}
              {/* <Th>Pipes</Th> */}
            </Tr>
          </THead>
          <TBody>
            {(listDestinations || []).map((destination) => (
              <Tr
                onClick={() =>
                  navigate(
                    namedURLs.destinations.show({
                      destinationID: destination.type.id,
                      id: destination.id,
                    }),
                  )
                }
                className="cursor-pointer"
              >
                <Td>
                  <Row className="justify-start space-x-4">
                    <div>{destination.name}</div>
                  </Row>
                </Td>
                <Td>
                  <Row className="justify-start space-x-2">
                    <DestinationLogo className="mr-2" variant="sm" value={destination.type.id} />
                    {destination.type.name}
                  </Row>
                </Td>
                {/* <Td>{credential.countPipes}</Td> */}
              </Tr>
            ))}
          </TBody>
        </Table>
      </Row>
    </Page>
  )
}

export default ListDestinationsPage
