import { reshapeBy } from 'common'
import { type ActionType, createSetInitialStateAction } from 'common/src/chainpipe/redux'
import * as React from 'react'
import type { useNavigate } from 'react-router-dom'
import type { useToast } from 'ui'
import { namedURLs, trpc } from '../../../../../../lib'
import { reducer } from './redux'

export function useDeleteContract(
  project: { id: string },
  pipeParams: Record<string, string | undefined>,
  toast: ReturnType<typeof useToast>['toast'],
  navigate: ReturnType<typeof useNavigate>,
) {
  const deleteMutation = trpc.evmContractsV1.delete.useMutation()

  const handleDelete = React.useCallback(() => {
    if (!project.id) {
      console.error('no project id')
      return
    } else if (!pipeParams['id']) {
      console.error('no pipeParams id')
      return
    } else {
      return deleteMutation
        .mutateAsync({
          project: {
            id: project.id,
          },
          pipe: {
            id: pipeParams.id,
          },
        })
        .then(() => {
          toast({ description: 'Contract has been deleted' })
          navigate(namedURLs.sources.list())
        })
    }
  }, [deleteMutation, project?.id, pipeParams.id, toast, navigate])

  return { handleDelete }
}

export const useEVMContractDetail = ({
  projectId,
  contractId,
}: {
  projectId: string
  contractId: string | undefined
}) => {
  const { data: evmContract } = trpc.evmContractsV1.show.useQuery(
    {
      project: { id: projectId },
      contractId: contractId!,
    },
    {
      enabled: !!(projectId && contractId),
    },
  )

  const { data: abiFragmentCoveragesInitial, isLoading } = trpc.evmContractsV1.abiFragmentCoverages.list.useQuery(
    {
      projectId,
      id: contractId!,
    },
    { enabled: !!contractId },
  )

  // @ts-expect-error
  const [state, dispatch] = React.useReducer(reducer, {
    abiFragments: {},
    evmContract,
    initialStateHasBeenSet: false,
    initialCountsHaveBeenSet: false,
  })

  React.useEffect(() => {
    if (abiFragmentCoveragesInitial) {
      dispatch(
        // @ts-expect-error
        createSetInitialStateAction({
          // @ts-expect-error
          evmContract,
          abiFragments: reshapeBy(abiFragmentCoveragesInitial || [], (i) => i.sighash),
        }),
      )
    }
  }, [abiFragmentCoveragesInitial])

  trpc.evmContractsV1.abiFragmentCoverages.watch.useSubscription(
    {
      projectId,
      id: contractId!,
    },
    {
      // @ts-expect-error need to properly type this
      onData(data: ActionType) {
        if (data.type === 'SET_INITIAL_STATE' || data.type === 'UPDATE_PROGRESS') {
          // @ts-expect-error need to properly type this
          dispatch(data)
        }
      },
      enabled: !!contractId,
    },
  )

  return {
    evmContract: state.evmContract,
    abiFragmentCoverages: Object.values(state.abiFragments),
    isLoading,
  }
}
