import React from 'react'
import clsx from 'clsx'
import type { RFC } from 'common-web'
import { Card } from '../../primitives'

interface ItemCardProps {
  icon: any
  title: string
  id: string
  description?: string
  href?: string
  disabled?: boolean
}

export const ItemCard: RFC<ItemCardProps> = ({
  icon,
  title,
  description,
  className,
  href = undefined,
  disabled = false,
  id,
  ...props
}) => {
  return (
    <Card
      className={clsx(
        'col-span-1 text-center h-full flex flex-col',
        {
          'hover:bg-blue-50 transition-all cursor-pointer': !disabled,
          'text-slate-600/70': disabled,
        },
        className,
      )}
      // onClick={onClick}
      key={id}
      {...props}
    >
      <div className="item-card__logo p-xl flex-grow-0">
        <div className="h-16 w-16 mx-auto">{icon}</div>
        {/* <DestinationLogo value={id} className="mx-auto" /> */}
      </div>
      <div className="item-card__description space-y-2 bg-slate-100/50 p-md border-t select-none flex-grow border-slate-200">
        <div className="item-card-title text-center text-base leading-tight">{title}</div>
        {description && <div className="text-center">{description}</div>}
      </div>
    </Card>
  )
}
