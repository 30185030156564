import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BetterForm, ButtonWithSpinner, CardWithContent, useToast } from 'ui'
import { Page } from '../../../components'
import { ProjectContext, useProjectId } from '../../../contexts'
import { type RouterInputs, trpc } from '../../../lib'
import { BillingCard } from './billing-card'

export const ProjectSettingsPage = () => {
  const updateProject = trpc.dashboard.settings.updateProject.useMutation()
  const { refetch } = useContext(ProjectContext)
  const { success } = useToast()
  const projectId = useProjectId()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onSubmit = React.useCallback(
    async (data: RouterInputs['dashboard']['settings']['updateProject']) => {
      try {
        setIsSubmitting(true)
        const updated = await updateProject.mutateAsync({
          ...data,
          projectId,
        })
        if (updated) {
          refetch()
          success({ title: 'Success', message: 'Project has been updated.' })
        }
      } catch (error) {
        setIsSubmitting(false)
      }
    },
    [refetch, projectId, setIsSubmitting],
  )

  const { data: project } = trpc.dashboard.settings.projectInfo.useQuery(
    {
      projectId,
    },
    {
      enabled: !!projectId,
    },
  )
  const form = useForm({
    defaultValues: {
      name: project?.name,
    },
  })

  useEffect(() => {
    if (project) {
      form.reset({
        name: project.name,
      })
    }
  }, [project, form]) // Dependency array includes `project` to re-run the effect when it changes

  return (
    <Page title="Settings" className="">
      <div className="grid grid-cols-1 gap-8">
        <CardWithContent title="Project Details" className="col-span-1">
          <BetterForm.Provider form={form} onSubmit={onSubmit}>
            <BetterForm.Field name="name" label="Project Name" autoFocus required />
            <ButtonWithSpinner isLoading={isSubmitting}>Update Project</ButtonWithSpinner>
          </BetterForm.Provider>
        </CardWithContent>
        <CardWithContent title="Project Billing" className="col-span-1">
          <BillingCard />
        </CardWithContent>
      </div>
    </Page>
  )
}
