import React from 'react'
import { Button, CardWithContent, clsx, type RFC, toast } from 'ui'
import { trpc } from '../../../../lib'

export const RunMigrationsWidget: RFC<{ projectId: string; id: string }> = ({
  className = undefined,
  projectId,
  id,
}) => {
  const { data: pendingMigrations, refetch } =
    trpc.destinations.managedPostgresDatabases.listPendingMigrations.useQuery(
      {
        projectId,
        id,
      },
      { placeholderData: [] },
    )

  const run = trpc.destinations.managedPostgresDatabases.runPendingMigrations.useMutation()

  const handleRunMigrations = React.useCallback(() => {
    run
      .mutateAsync({
        projectId,
        id,
      })
      .then((result) => {
        refetch()
        toast({
          variant: 'success',
          description: 'Migrations have been successfully run',
        })
        // setPendingMigrations([])
      })
      .catch((err) => {
        toast({ variant: 'destructive', description: err.toString() })
      })
  }, [run, projectId, id, refetch])

  return (
    <CardWithContent title="Run Migrations" description="Keep your database up to date" className={clsx(className, '')}>
      <div className="space-y-lg">
        {/* <div>
          <Button onClick={handleClick}>Check for Migrations</Button>
        </div> */}
        {/* {(pendingMigrations || []).length > 0 && (
          <div className="space-y-md">
            <h3>Pending Migrations Ready</h3>
            <ul>
              {(pendingMigrations || []).map((mig) => (
                <li className="font-mono" key={mig?.name}>
                  {mig?.name}
                </li>
              ))}
            </ul>
          </div>
        )} */}
        <Button disabled={pendingMigrations?.length === 0} variant="green" onClick={handleRunMigrations}>
          {(pendingMigrations || [])?.length > 0 ? 'Run Pending Migrations' : 'Up to Date'}
        </Button>
        {/* </div> */}
        {/* )} */}
      </div>
    </CardWithContent>
  )
}
