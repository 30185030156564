import React from 'react'
import { SpinnerWithLabel } from 'ui'
import { ButtonLink, Page } from '../../../components'
import { trpc } from '../../../lib'

export const BillingCard = () => {
  const customerDetails = trpc.dashboard.billing.getCustomerDetails.useQuery({
    returnUrl: window.location.href,
  })

  return (
    <div>
      {customerDetails.data ? (
        <ButtonLink to={customerDetails?.data?.billingPortalUrl} target="_blank">
          Access Customer Billing Portal
        </ButtonLink>
      ) : (
        <SpinnerWithLabel>Loading</SpinnerWithLabel>
      )}
    </div>
  )
}
