import React, { useState } from 'react'
import { DestinationTypeID } from 'common'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BetterForm, ButtonWithSpinner } from 'ui'
import { useProjectId } from '../../../../components'
import { Page } from '../../../../components/page'
import { type BFC, namedURLs, trpc } from '../../../../lib'

export const NewPostgresManagedDbScreen: BFC = (props) => {
  const form = useForm({
    defaultValues: {},
  })
  const navigate = useNavigate()
  const createPostgresManagedDb = trpc.destinations.managedPostgresDatabases.create.useMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const projectId = useProjectId()
  const onSubmit = React.useCallback(
    async (values: { name: string }) => {
      try {
        setIsSubmitting(true)
        const data = await createPostgresManagedDb.mutateAsync({
          projectId,
          name: values.name,
        })
        if (data?.id) {
          navigate(
            namedURLs.destinations.show({
              destinationID: DestinationTypeID.MANAGED_UPSTREAM_POSTGRES,
              id: data.id,
            }),
          )
        }
      } catch (error) {
        setIsSubmitting(false)
      }
    },
    [setIsSubmitting, createPostgresManagedDb, projectId, navigate],
  )

  return (
    <Page {...props} title="New Postgres Managed DB" description="Access a managed DB.">
      <BetterForm.Provider form={form} onSubmit={onSubmit}>
        <BetterForm.Field name="name" placeholder="Production DB" label="A helpful name for this object" />
        <ButtonWithSpinner isLoading={isSubmitting}>Create Managed Postgres</ButtonWithSpinner>
      </BetterForm.Provider>
    </Page>
  )
}
