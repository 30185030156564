import * as Sentry from '@sentry/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, CenterLogo, type RFC, buttonVariants, useCenterAbsoluteUrl } from 'ui'
import { useHasProject } from '../../contexts/project-context'
import { type BFC, clsx } from '../../lib/utils'
import { ProjectSwitcher } from '../project-switcher'
import { apiLinks, indexingLinks, settingsLinks } from './links'
import type { LinkType } from './types'

const NavGroup: RFC<{ title: string }> = ({ title, children = undefined, className = undefined, ...props }) => (
  <div className={clsx('space-y-2', className)} {...props}>
    <div className="text-muted-foreground select-none text-sm text-[#111729]">{title}</div>
    {children}
  </div>
)

type INavbarGroupWithLinksBaseProps = {
  links: Array<LinkType>
  title: string
  disableLinks?: boolean
}

const NavGroupWithLinks: RFC<INavbarGroupWithLinksBaseProps> = ({
  title,
  links,
  children = undefined,
  disableLinks,
  ...props
}) => {
  const location = useLocation()

  return (
    <div className="space-y-2" {...props}>
      <div className="text-muted-foreground text-sm text-[#111729] select-none">{title}</div>
      <div className="flex flex-col gap-y-2">
        {links.map(({ matchIf, ...link }, index) => {
          const isActive =
            typeof matchIf !== 'undefined' && typeof matchIf === 'function'
              ? matchIf(location.pathname)
              : location.pathname === link.to

          return (
            <Link
              key={index}
              to={link.to}
              className={clsx(buttonVariants({ variant: 'ghost', size: 'sm' }), 'justify-between items-start', {
                'disabled pointer-events-none': !!disableLinks,
                'text-[#111729] bg-slate-200 hover:!bg-slate-200': isActive,
                'text-[#677489] hover:bg-slate-300': !isActive,
              })}
            >
              {/* {link.icon ? React.createElement(link.icon, { }) : <div />} */}
              <span className="select-none">{link.title}</span>
              <div />
            </Link>
          )
        })}
        {children}
      </div>
    </div>
  )
}

export const LeftNavHeader = () => (
  <div className="logo-marketing">
    <Link className="text-foreground" to="/">
      <CenterLogo className="w-4" />
    </Link>
  </div>
)

export const LeftNav: BFC = ({ className = undefined, ...props }) => {
  const hasProject = useHasProject()
  return (
    <div className={clsx('group h-full space-y-md gap-md', className)} {...props}>
      <NavGroup title="Project" className="mb-lg">
        <ProjectSwitcher />
      </NavGroup>
      <div className="space-y-lg">
        <NavGroupWithLinks title="Contract Indexing" links={indexingLinks} disableLinks={!hasProject} />
        <NavGroupWithLinks title="APIs" links={apiLinks} disableLinks={!hasProject} />
        <NavGroupWithLinks title="Settings" links={settingsLinks} disableLinks={!hasProject} />
      </div>
    </div>
  )
}

export const LeftNavFooter = () => {
  const logoutUrl = useCenterAbsoluteUrl({
    subdomain: 'auth',
    path: '/api/auth/logout/',
  })
  const nextUrl = useCenterAbsoluteUrl({
    subdomain: null,
    path: '/',
  })

  const onLogout = React.useCallback(() => {
    Sentry.setUser(null)
  }, [])

  return (
    <>
      <form method="post" action={logoutUrl} onSubmit={onLogout}>
        <input type="hidden" name="next" value={nextUrl} />
        <Button type="submit" variant="ghost" className="w-full justify-start">
          Log Out
        </Button>
      </form>
    </>
  )
}
