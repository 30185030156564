import { clsx } from 'ui'
import type { BFC } from '../lib/utils'

export const FormGroup: BFC<{ label: string; description?: string }> = ({
  className = undefined,
  children = undefined,
  label,
  description = undefined,
  ...props
}) => {
  return (
    <div className={clsx('space-y-2', className)} {...props}>
      <div className="text-slate-700 font-medium">{label}</div>
      <div className="form-control">{children}</div>
      <div>{description}</div>
    </div>
  )
}
