import type { DestinationTypeID, SourceTypeID } from 'common'

export const namedURLs = {
  homePage: '',
  sources: {
    index: () => '/sources',
    add: () => '/sources/new',
    list: () => '/sources',

    new: (params: { sourceID: SourceTypeID }) => `/sources/new/${params.sourceID}`,

    show: (params: { sourceID: SourceTypeID; id: string }) => `/sources/show/${params.sourceID}/${params.id}`,

    showABIFragmentDetail: (params: {
      sourceID: SourceTypeID.EVM_CONTRACT
      id: string
      claimID: string
    }) => `/sources/show/${params.sourceID}/${params.id}/${params.claimID}`,
  },
  projects: {
    new: () => '/projects/new',
  },
  destinations: {
    add: () => '/destinations/new',
    new: (params: { destinationID: DestinationTypeID }) => `/destinations/new/${params.destinationID}`,

    show: (params: { destinationID: DestinationTypeID; id: string }) =>
      `/destinations/show/${params.destinationID}/${params.id}`,
  },
}

export function getAuthUrls(apex: string) {
  const authDomain = `auth.${apex}`
  // protocol is like http: -- so we need the double slash
  const authUrlPrefix = `https://${authDomain}`
  const urlForRedirect = new URL(window.location.href)
  urlForRedirect.pathname = namedURLs.homePage
  const urlEncodedRedirect = urlForRedirect.toString()

  function appendQueryParams(baseUrl: string) {
    const originalUrl = new URL(baseUrl)
    const searchParams = new URLSearchParams({ next: urlEncodedRedirect })
    originalUrl.search = searchParams.toString()
    return originalUrl.toString()
  }

  const loginUrl = appendQueryParams(`${authUrlPrefix}/api/auth/redirect`)

  return {
    /**
     * @see packages/auth-api/src/routes/credentials.ts
     */
    login: loginUrl,
    resetPassword: `${authUrlPrefix}/reset`,
    logout: `${authUrlPrefix}/api/auth/logout`,
    credentials: {
      signUp: appendQueryParams(`${authUrlPrefix}/api/auth/signup`),
      login: appendQueryParams(`${authUrlPrefix}/api/auth/login`),
    },
    google: {
      login: appendQueryParams(`${authUrlPrefix}/api/login/google`),
    },
    github: {
      login: appendQueryParams(`${authUrlPrefix}/api/login/github`),
    },
  }
}
