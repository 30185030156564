import NumberFormat from 'react-number-format'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Card, type RFC } from 'ui'
import { Page } from '../../components'
import { StackedHistogram } from '../../components/stacked-histogram'
import { StackedProgressBar } from '../../components/stacked-progress-bar'
import { useProject, useProjectId } from '../../contexts'
import { namedURLs, trpc } from '../../lib'
import { formatDate } from '../../lib/date'

const InvertedCard = ({
  children,
  label,
}: {
  children?: React.ReactNode
  label: string
}) => (
  <Card className="flex flex-col p-4">
    <div className="p-0 flex-grow-0 text-slate-600 text-sm">{label}</div>
    <div className="p-0 flex-grow pt-2">{children}</div>
  </Card>
)

const DashboardOverviewPage: RFC = ({ className = undefined, children = undefined, ...props }) => {
  const projectId = useProjectId()
  if (!projectId) {
    return null
  }
  const { data: usageSummary } = trpc.dashboard.overview.usageSummary.useQuery({
    projectId,
  })

  const { data: endpointUnitUsage } = trpc.dashboard.overview.endpointUnitUsage.useQuery(
    {
      projectId: projectId!,
    },
    {
      enabled: !!projectId,
    },
  )

  const { data: endpointUnitUsageTimeseries } = trpc.dashboard.overview.endpointUnitUsageTimeseries.useQuery({
    projectId,
  })

  return (
    <Page title="Overview" {...props}>
      {usageSummary?.hasMeteredUsage && (
        <div className="grid grid-cols-2 gap-4">
          <InvertedCard label="Compute Units Used">
            <NumberFormat
              className="text-3xl"
              thousandSeparator
              displayType="text"
              value={usageSummary?.computeUnits?.used ?? 0}
            />
            <span className="mx-1 text-xs text-slate-500">/</span>
            <NumberFormat thousandSeparator displayType="text" value={usageSummary?.computeUnits?.total ?? 0} />
          </InvertedCard>

          <InvertedCard label="Usage Period resets">
            <div className="text-3xl">
              {usageSummary.usagePeriodRestarts?.dt
                ? formatDate(usageSummary.usagePeriodRestarts?.dt?.toDateString())
                : null}
            </div>
          </InvertedCard>
          {/* <InvertedCard label="Weekly CU Growth">
            <div className="text-3xl">
              <FormatPercent value={usageSummary.weeklyComputeUnitUsageGrowth} />
            </div>
          </InvertedCard> */}
        </div>
      )}

      <Card padded className="space-y-md">
        <p>Endpoint Usage Breakdown</p>
        <StackedProgressBar values={endpointUnitUsage?.values} maxValue={endpointUnitUsage?.maxValue ?? 100} />
      </Card>
      <Card padded className="space-y-md">
        <p>Usage Over The Last Month</p>
        <div className="w-full min-h-[500px]">
          <AutoSizer>
            {({ width, height }) => (
              <StackedHistogram
                keys={endpointUnitUsageTimeseries?.operationIds || []}
                data={endpointUnitUsageTimeseries?.timeseries || []}
                height={height}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </Card>
    </Page>
  )
}

export default DashboardOverviewPage
