import type { NetworkID } from 'common'
import React from 'react'
import { NetworkLabel } from 'ui'

export const ContractInfoTable = ({
  data,
}: {
  data: { network: NetworkID; address?: string; name?: string }
}) => {
  return (
    <table className="table text-sm">
      <tbody>
        <tr>
          <td className="text-slate-600">Contract Name</td>
          <td>{data?.name || ''}</td>
        </tr>
        <tr>
          <td className="text-slate-600">Network</td>
          <td>{data?.network ? <NetworkLabel network={data?.network} /> : ''}</td>
        </tr>
        <tr>
          <td className="text-slate-600">Address</td>
          <td className="font-mono">{data?.address || ''}</td>
        </tr>
      </tbody>
    </table>
  )
}
