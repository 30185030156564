import React from 'react'
import { ArrowUpRight } from 'lucide-react'
import { Link, Outlet, type LinkProps } from 'react-router-dom'
import {
  type BreadcrumbItemType,
  Breadcrumbs,
  clsx,
  type RFC,
  TwoColumnDashboardLayout,
  useCenterAbsoluteUrl,
} from 'ui'
import { LeftNav, LeftNavFooter, LeftNavHeader } from '../../components/left-nav/left-nav'
import { AuthGate } from '../../contexts/auth-context'
import type { OutletContextType } from '../outlet'

export const DashboardLayout: RFC = ({ className = undefined, ...props }) => {
  const [title, setTitle] = React.useState<string>('')
  const [breadcrumbs, setBreadcrumbs] = React.useState<Array<BreadcrumbItemType>>([])

  const renderLink = ({
    children,
    href,
    ...props
  }: {
    children: React.ReactNode
    href: string
    props: Omit<LinkProps, 'to'>
  }) => (
    <Link {...props} to={href}>
      {children}
    </Link>
  )

  const bodyHeaderComponent = React.useMemo(() => {
    const contactUrl = useCenterAbsoluteUrl({
      subdomain: null,
      path: '/contact',
    })

    const docsUrl = useCenterAbsoluteUrl({
      subdomain: null,
      path: '/docs',
    })

    return (
      <div className={clsx('flex w-full justify-between')}>
        {/** @ts-expect-error */}
        <Breadcrumbs pages={breadcrumbs} renderLink={renderLink} />
        <div className="links justify-end space-x-4 hidden lg:flex">
          {[
            { label: 'Docs', href: docsUrl },
            { label: 'Contact', href: contactUrl },
          ].map((m) => (
            <Link
              key={m.href}
              target="_blank"
              to={m.href}
              className="flex text-sm items-center text-slate-400 cursor-pointer transition-all hover:text-slate-600"
            >
              <div>{m.label}</div> <ArrowUpRight className="h-4 w-4" />
            </Link>
          ))}
          {/* <AvatarWithFallback className="w-8 h-8" /> */}
        </div>
      </div>
    )
  }, [breadcrumbs])

  return (
    <AuthGate>
      <TwoColumnDashboardLayout
        navHeader={<LeftNavHeader />}
        navClassName={'bg-slate-50 border-r'}
        navMain={<LeftNav />}
        headerClassName={'border-slate-200 border-b'}
        navFooter={<LeftNavFooter />}
        bodyHeader={bodyHeaderComponent}
      >
        <Outlet
          context={
            {
              title,
              setTitle,
              breadcrumbs,
              setBreadcrumbs,
            } satisfies OutletContextType
          }
        />
      </TwoColumnDashboardLayout>
    </AuthGate>
  )
}
