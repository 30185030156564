import clsx from 'clsx'
import type { OptionType } from 'common-web'
import React from 'react'
import { Debug, Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../primitives'

type SelectInputProps<T extends string> = React.HTMLProps<HTMLButtonElement> & {
  options: Array<OptionType<T>>
  onValueChange?: (value: T) => void
  defaultValue?: T
  placeholder?: string
  value?: T
  renderLabelWrapper?: (value: string, label: string) => React.ReactNode
}

export const SelectInput = React.forwardRef<any, SelectInputProps<string>>((params, ref) => {
  const { options, className, placeholder, onValueChange, defaultValue, value, renderLabelWrapper, ...props } = params

  return (
    <Select onValueChange={onValueChange} defaultValue={defaultValue} value={value}>
      {/* @ts-ignore */}
      <SelectTrigger ref={ref} className={clsx('bg-white text-slate-900', className)} {...props}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map(({ value, label }) => (
            <SelectItem value={value} key={value}>
              {renderLabelWrapper ? renderLabelWrapper(value, label) : label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
})
