import React from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

type Props = {
  className?: any
  countNewEvents: number
  currentTip: number
  isLoading: boolean
} & React.HTMLProps<HTMLDivElement>

export const NewEventsCard = ({
  className,
  onClick,
  countNewEvents = 0,
  currentTip = 0,
  isLoading = false,
  ...props
}: Props) => {
  const message = React.useMemo(() => {
    if (isLoading) {
      return <div>Loading</div>
    } else if (countNewEvents > 0) {
      return (
        <>
          <div>{`There ${countNewEvents === 1 ? 'is' : 'are'}`}</div>
          <NumberFormat className="font-medium" thousandSeparator value={countNewEvents} displayType="text" />
          <div>new event{countNewEvents === 1 ? '' : 's'}.</div>
          {/* <NumberFormat
            className="font-medium"
            thousandSeparator
            value={currentTip}
            displayType="text"
          /> */}
          <div>Click to load.</div>
        </>
      )
    } else {
      return (
        <>
          <div>No new events detected</div>

          {/* {' '}
          <NumberFormat
            className="font-medium"
            thousandSeparator
            value={currentTip}
            displayType="text"
          />{' '} */}
        </>
      )
    }
  }, [isLoading, countNewEvents])

  return (
    <div
      onClick={countNewEvents > 0 ? onClick : undefined}
      className={clsx('rounded-sm  border-slate-200 text-slate-600 border p-md', className, {
        'cursor-pointer bg-slate-50 hover:bg-slate-50/25': countNewEvents > 0,
      })}
      {...props}
    >
      <div className="flex space-x-1">{message}</div>
    </div>
  )
}
