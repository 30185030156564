import { ButtonLink, ContractWithAddress, ContractWithAddressCell } from '@/components'
import { useProjectId } from '@/contexts'
import { trpc } from '@/lib'
import { namedURLs } from '@/lib/named-routes'
import { SourceTypeID } from 'common'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BlockRange, Button, THead, Table, Td, Th, Tr } from 'ui'

export const RelatedContractsTab = ({
  contractId,
  ...props
}: {
  contractId: string
}) => {
  const projectID = useProjectId()
  const [newAddress, setNewAddress] = useState('')

  const { data: implementationAddresses } = trpc.evmContractsV1.listImplementationAddresses.useQuery(
    {
      project: {
        id: projectID,
      },
      claimId: contractId,
    },
    {
      placeholderData: [],
      enabled: !!projectID,
    },
  )

  const handleAddAddress = () => {
    // Add address logic here
  }

  const handleDeleteAddress = (address: string) => {
    // Delete address logic here
  }
  const navigate = useNavigate()

  return (
    <div {...props}>
      <ButtonLink to="?tab=add-related" leftIcon={<Plus className="h-4 w-4" />}>
        Add Related Contract
      </ButtonLink>
      <div>
        <Table className="w-full">
          <THead>
            <Tr>
              <Th>Address</Th>
              <Th right>Related Block Range</Th>
              {/* <Th center>Edit</Th> */}
            </Tr>
          </THead>
          <tbody>
            {implementationAddresses?.map((ct) => (
              <Tr
                className="cursor-pointer"
                onClick={() => navigate(namedURLs.sources.show({ sourceID: SourceTypeID.EVM_CONTRACT, id: ct.id }))}
                key={ct.id}
              >
                <ContractWithAddressCell name={ct.label || '(Unnamed)'} address={ct.address} />
                <Td right>
                  <BlockRange start={ct.blockRange?.from} end={ct.blockRange?.to} />
                </Td>
                {/* <Td className="text-center">Edit</Td> */}
              </Tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
