import { AlertCircle } from 'lucide-react'
import * as React from 'react'
import { cn } from '../lib'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = 'text', leadingIcon, trailingIcon, error = '', ...props }, ref) => {
    if (error.length > 0) {
      trailingIcon = <AlertCircle className="stroke-red-500" />
    }
    return (
      <div>
        <div className="">
          {leadingIcon && (
            <div className="absolute left-3 top-0 bottom-0 flex items-center w-5 h-auto">{leadingIcon}</div>
          )}
          <input
            type={type}
            autoComplete={type === 'password' ? 'new-password' : 'off'}
            className={cn(
              'border-gray-300 shadow-sm text-gray-900 bg-white placeholder:text-gray-500 focus-visible:ring-ring flex w-full rounded-lg border px-3 py-2 text-base transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-4 focus-visible:shadow disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:opacity-50 z-10 leading-6 font-inter',
              leadingIcon ? 'pl-10' : '',
              trailingIcon ? 'pr-10' : '',
              error.length > 0
                ? 'focus-visible:border-red-300 focus-visible:ring-red-100'
                : 'focus-visible:border-brand-primary focus-visible:ring-brand-focus',
              className,
            )}
            ref={ref}
            {...props}
          />
          {trailingIcon && (
            <div className="absolute right-3 top-0 bottom-0 flex items-center w-5 h-auto">{trailingIcon}</div>
          )}
        </div>
        {error && <p className="text-red-500 text-sm leading-5 mt-xxs">{error}</p>}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
