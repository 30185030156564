import * as Sentry from '@sentry/react'
import { DestinationTypeID, SourceTypeID, inflection } from 'common'
import { Link, Navigate, type RouteObject, createBrowserRouter } from 'react-router-dom'
import type { RFC } from 'ui'
import { AuthLayout } from './components/auth-layout'
import { ErrorBoundary } from './components/error-boundary'
import { ProjectGate } from './contexts/project-context'
import { trpc } from './lib'
import ApiKeysPage from './pages/dashboard/api-keys/index.page'
import NewApiKeyPage from './pages/dashboard/api-keys/new.page'
import ShowApiKeyPage from './pages/dashboard/api-keys/show.page'
import ListDestinationsPage from './pages/dashboard/destinations/index.page'
import NewDestinationPage from './pages/dashboard/destinations/new.page'
import { NewFDWScreen } from './pages/dashboard/destinations/postgres-fdw-v1/new.page'
import ShowDestinationPage from './pages/dashboard/destinations/postgres-fdw-v1/show.page'
import { NewPostgresManagedDbScreen } from './pages/dashboard/destinations/postgres-managed-db/new.page'
import { ShowPostgresGatewayPage } from './pages/dashboard/destinations/postgres-managed-db/show.page'
import DashboardOverviewPage from './pages/dashboard/index.page'
import { DashboardLayout } from './pages/dashboard/layout'
import PerformancePage from './pages/dashboard/performance.page'
import ShowPipePage from './pages/dashboard/pipes/[id].page'
import ListPipesPage from './pages/dashboard/pipes/index.page'
import NewPipePage from './pages/dashboard/pipes/new.page'
import PlanPage from './pages/dashboard/plan.page'
import { PostCheckoutPage } from './pages/dashboard/post-checkout'
import { NewProjectPage, ProjectsIndexPage } from './pages/dashboard/projects.page'
import { BillingCard } from './pages/dashboard/settings/billing-card'
import { ProjectSettingsPage } from './pages/dashboard/settings/project-settings.page'
import ShowSighashPage from './pages/dashboard/sources/evm-contract-v1/[id]/[sighash]/index.page'
import ShowEVMContractPage from './pages/dashboard/sources/evm-contract-v1/[id]/index.page'
import NewEVMContractPage from './pages/dashboard/sources/evm-contract-v1/new.page'
import { NewEvmEventLogPage } from './pages/dashboard/sources/evm-event-log-v1/new.page'
import EVMContractsIndexPage from './pages/dashboard/sources/index.page'
import ListEVMContractsPage from './pages/dashboard/sources/index.page'
import NewSourcePage from './pages/dashboard/sources/new.page'
import TeamPage from './pages/dashboard/team.page'
import { TransFormsIndexPage } from './pages/dashboard/transforms/index'
import UsagePage from './pages/dashboard/usage/index.page'
import UserPage from './pages/dashboard/user.page'
import AcceptInvitationPage from './pages/invitations/accept.page'
import LandingPage from './pages/landing/index.page'

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const NewProjectPageWrapper: RFC = ({ children }) => {
  const auth = trpc.auth.whoami.useQuery()

  if (auth.data?.projects.length === 0) {
    return children
  }

  return <ProjectGate>{children}</ProjectGate>
}

const dashboardRoutes: RouteObject[] = [
  {
    path: '/projects/new',
    element: (
      <NewProjectPageWrapper>
        <DashboardLayout />
      </NewProjectPageWrapper>
    ),
    children: [
      {
        path: '',
        element: <NewProjectPage />,
        handle: {
          breadcrumb: {
            name: 'New',
          },
        },
      },
    ],
  },

  {
    path: '/invitations/:key/accept/',
    element: <AcceptInvitationPage />,
    handle: {
      breadcrumb: {
        name: 'Accept Invitation',
      },
    },
  },

  {
    element: (
      <ProjectGate>
        <DashboardLayout />
      </ProjectGate>
    ),
    handle: {
      breadcrumb: {
        name: 'Dashboard',
      },
    },
    children: [
      {
        path: '',
        element: <Navigate to="sources" />,
        handle: {
          breadcrumb: {
            name: 'Sources',
          },
        },
      },
      {
        path: 'api-overview',
        element: <DashboardOverviewPage />,
        handle: {
          breadcrumb: {
            name: 'Overview',
          },
        },
      },
      {
        path: 'usage',
        element: <UsagePage />,
        handle: {
          breadcrumb: {
            name: 'Usage',
          },
        },
      },
      {
        path: 'performance',
        element: <PerformancePage />,
      },
      {
        path: 'billing',
        element: <BillingCard />,
      },
      {
        path: 'plan',
        element: <PlanPage />,
      },
      {
        path: 'post-checkout',
        element: <PostCheckoutPage />,
      },
      {
        path: 'api-keys',
        handle: {
          breadcrumb: {
            name: 'API Keys',
          },
        },
        children: [
          {
            path: '',
            element: <ApiKeysPage />,
            handle: {
              breadcrumb: {
                name: 'List',
              },
            },
          },
          {
            path: ':id',
            element: <ShowApiKeyPage />,
            handle: {
              breadcrumb: {
                name: 'Detail',
              },
            },
          },
          {
            path: 'new',
            element: <NewApiKeyPage />,
            handle: {
              breadcrumb: {
                name: 'New',
              },
            },
          },
        ],
      },
      {
        path: 'team',
        element: <TeamPage />,
        handle: {
          breadcrumb: {
            name: 'Team',
          },
        },
      },

      {
        path: 'user',
        element: <UserPage />,
        handle: {
          breadcrumb: {
            name: 'User',
          },
        },
      },

      {
        path: 'settings',
        element: <ProjectSettingsPage />,
        handle: {
          breadcrumb: {
            name: 'Settings',
          },
        },
      },
      {
        path: 'projects',
        handle: {
          breadcrumb: {
            name: 'Projects',
          },
        },
        children: [
          {
            path: '',
            element: <ProjectsIndexPage />,
          },
        ],
      },
      {
        path: 'sources',
        handle: {
          breadcrumb: {
            name: 'Sources',
          },
        },
        children: [
          {
            path: '',
            element: <ListEVMContractsPage />,
          },
          {
            path: 'new',
            children: [
              {
                handle: {
                  breadcrumb: {
                    name: 'New',
                  },
                },
                path: '',
                element: <NewSourcePage />,
              },
              {
                path: `${SourceTypeID.EVM_CONTRACT}`,
                element: <NewEVMContractPage />,
                handle: {
                  breadcrumb: {
                    name: 'New EVM Contract',
                  },
                },
              },
              {
                path: `${SourceTypeID.EVM_EVENT_LOG}`,
                element: <NewEvmEventLogPage />,
                handle: {
                  breadcrumb: {
                    name: 'New EVM Event Log',
                  },
                },
              },
            ],
          },
          {
            path: `show/${SourceTypeID.EVM_CONTRACT}/:id`,
            handle: {
              breadcrumb: {
                // TODO: this should be dynamic.
                name: 'Show EVM Contract',
              },
            },
            children: [
              {
                path: '',
                element: <ShowEVMContractPage />,
              },
              {
                path: ':fragmentId',
                element: <ShowSighashPage />,
                handle: {
                  breadcrumb: {
                    // TODO: this should be dynamic.
                    name: 'Fragment Detail',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'transforms',
        handle: {
          breadcrumb: {
            name: 'Transforms',
          },
        },
        children: [
          {
            path: '',
            element: <TransFormsIndexPage />,
          },
        ],
      },
      {
        path: 'destinations',
        handle: {
          breadcrumb: {
            name: 'Destinations',
          },
        },
        children: [
          {
            path: '',
            element: <ListDestinationsPage />,
          },

          {
            path: 'new',
            children: [
              {
                handle: { breadcrumb: { name: 'New' } },
                path: '',
                element: <NewDestinationPage />,
              },
              {
                path: `${DestinationTypeID.MANAGED_UPSTREAM_POSTGRES}`,
                element: <NewPostgresManagedDbScreen />,
                handle: { breadcrumb: { name: 'New Postgres Managed Database' } },
              },
              {
                path: `${DestinationTypeID.POSTGRES_FDW}`,
                element: <NewFDWScreen />,
                handle: { breadcrumb: { name: 'New Foreign Data Wrapper' } },
              },
            ],
          },

          {
            path: `show/${DestinationTypeID.POSTGRES_FDW}/:id`,
            element: <ShowDestinationPage />,
            // TODO: dynamic
            handle: { breadcrumb: { name: 'Foreign Data Wrapper' } },
          },
          {
            path: `show/${DestinationTypeID.MANAGED_UPSTREAM_POSTGRES}/:id`,
            element: <ShowPostgresGatewayPage />,
            // TODO: dynamic
            handle: { breadcrumb: { name: 'Managed Postgres Server' } },
          },
        ],
      },
      // {
      //   path: 'pipes',
      //   element: <ListPipesPage />,
      //   children: [
      //     {
      //       path: 'new',
      //       element: <NewPipePage />,
      //     },
      //     { path: ':id', element: <ShowPipePage /> },
      //   ],
      // },
    ],
  },
]

export const router = sentryCreateBrowserRouter(dashboardRoutes, {})
