import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

export const formatDate = (date: string, format = 'L') => {
  const dt = dayjs(date)

  return dt.format(format)
}
