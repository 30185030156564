import React from 'react'
import { DestinationTypeID, CreatePostgresFormVersion as FormVersion } from 'common'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  clsx,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  RFC,
  Row,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useToast,
  zod as z,
  type zod,
  zodResolver,
} from 'ui'
import { Page } from '../../../../components'
import { useProjectId } from '../../../../contexts'
import { type BFC, RouterInputs, trpc } from '../../../../lib'
import { namedURLs } from '../../../../lib/named-routes'

const manualFormSchema = z.object({
  credentials: z.object({
    user: z.string(),
    host: z.string(),
    password: z.string(),
    port: z.number().default(5432),
    database: z.string(),
    schema: z.string().default('public'),
  }),
})

const databaseURLFormSchema = z.object({
  credentials: z.object({
    databaseURL: z.string(),
    // .url({
    //   message:
    //     'Is this a database URL? It should start with postgres:// and end with a database name.',
    // }),
  }),
})

// type ICreatePostgresFDWMutationFormValues = Omit<
//   RouterInputs['destinations']['createPostgresFDW'],
//   'project'
// >

type FormSubmitProps<T> = {
  onSubmit: (values: T) => Promise<void>
}

type IManualFormSchema = zod.infer<typeof manualFormSchema>
type ICreateFDW = never

// const ManualDetailsForm: RFC<FormSubmitProps<ICreatePostgresFDWMutationFormValues>> = ({
//   onSubmit,
// }) => {
//   const form = useForm<zod.infer<typeof manualFormSchema>>({
//     resolver: zodResolver(manualFormSchema),
//     defaultValues: {
//       credentials: {
//         port: 5432,
//         schema: 'public',
//         // // remove after dev
//         // host: 'localhost',
//         user: 'postgres',
//         // password: 'password',
//         // database: 'center_development',
//       },
//     },
//   })

//   const handleSubmit = (values: IManualFormSchema) =>
//     onSubmit({
//       ...values,
//       credentials: {
//         ...values.credentials,
//         kind: FormVersion.MANUAL,
//       },
//     })

//   return (
//     <Form {...form}>
//       <form
//         className="w-full grid grid-cols-2 gap-4"
//         autoComplete="off"
//         onSubmit={form.handleSubmit(handleSubmit)}
//       >
//         <FormField
//           control={form.control}
//           name="credentials.host"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Hostname</FormLabel>
//               <FormControl>
//                 <Input {...field} placeholder="db.mycompany.com" autoFocus />
//               </FormControl>
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="credentials.port"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Port</FormLabel>
//               <FormControl>
//                 <Input {...field} />
//               </FormControl>
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="credentials.user"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>User</FormLabel>
//               <FormControl>
//                 <Input {...field} placeholder="postgres" />
//               </FormControl>
//             </FormItem>
//           )}
//         />

//         <FormField
//           control={form.control}
//           name="credentials.password"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Password</FormLabel>
//               <FormControl>
//                 <Input {...field} type="password" placeholder="hunter42" />
//               </FormControl>
//             </FormItem>
//           )}
//         />

//         <FormField
//           control={form.control}
//           name="credentials.database"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Database Name</FormLabel>
//               <FormControl>
//                 <Input {...field} />
//               </FormControl>
//             </FormItem>
//           )}
//         />

//         <FormField
//           control={form.control}
//           name="credentials.schema"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Schema Name</FormLabel>
//               <FormControl>
//                 <Input {...field} />
//               </FormControl>
//             </FormItem>
//           )}
//         />

//         <Button type="submit" className="col-span-2">
//           Create Connection
//         </Button>
//       </form>
//     </Form>
//   )
// }

// type IDatabaseUrlFormSchema = zod.infer<typeof databaseURLFormSchema>
// const DatabaseURLForm: RFC<FormSubmitProps<ICreatePostgresFDWMutationFormValues>> = ({
//   className = undefined,
//   children = undefined,
//   onSubmit,
//   ...props
// }) => {
//   const handleSubmit = (values: IDatabaseUrlFormSchema) =>
//     onSubmit({
//       ...values,
//       credentials: {
//         ...values.credentials,
//         kind: FormVersion.DATABASE_URL,
//       },
//     })

//   const form = useForm<IDatabaseUrlFormSchema>({
//     resolver: zodResolver(databaseURLFormSchema),
//     defaultValues: {
//       credentials: {
//         // kind: FormVersion.DATABASE_URL,
//         // databaseURL: 'postgres://postgres@localhost:5432/center_development',
//       },
//     },
//   })

//   return (
//     <div className={clsx(className)} {...props}>
//       <Form {...form}>
//         <form
//           className="w-full grid grid-cols-2 gap-4"
//           autoComplete="off"
//           onSubmit={form.handleSubmit(handleSubmit)}
//         >
//           <FormField
//             control={form.control}
//             name="credentials.databaseURL"
//             render={({ field }) => (
//               <FormItem className="col-span-2">
//                 <FormLabel>Database URL</FormLabel>
//                 <FormControl>
//                   <Input
//                     // autoFocus={true}
//                     placeholder={`postgres://postgres@localhost:5432/database_name`}
//                     {...field}
//                   />
//                 </FormControl>
//               </FormItem>
//             )}
//           />
//           <Button
//             className="col-span-2"
//             type="submit"
//             disabled={form.formState.isSubmitting}
//           >
//             {form.formState.isSubmitting ? 'Creating Connection' : 'Create Connection'}
//           </Button>
//         </form>
//       </Form>
//     </div>
//   )
// }

export const NewFDWScreen: BFC = (props) => {
  // const projectId = useProjectId()
  // const createConnection = trpc.destinations.createPostgresFDW.useMutation()
  // const navigate = useNavigate()
  // const { toast } = useToast()

  // const handleSubmit = async (values: ICreatePostgresFDWMutationFormValues) => {
  //   // const result = await createConnection
  //   //   .mutateAsync({
  //   //     project: { id: projectId },
  //   //     ...values,
  //   //   })
  //   //   .catch((err: any) => {
  //   //     toast({ variant: 'destructive', description: err.toString() })
  //   //     return null
  //   //   })
  //   // if (result && result.success) {
  //   //   type G = typeof result
  //   //   toast({ variant: 'success', description: 'Database connection created.' })
  //   //   navigate(
  //   //     namedURLs.destinations.show({
  //   //       destinationID: DestinationTypeID.POSTGRES_FDW,
  //   //       id: result.connection.id,
  //   //     })
  //   //   )
  //   // } else if (result) {
  //   //   toast({
  //   //     variant: 'destructive',
  //   //     description: `Could not connect: ${result.error}`,
  //   //   })
  //   // }
  // }

  // return (
  //   <Page
  //     {...props}
  //     title="New Database Connection"
  //     description="Connect your remote database to chainpipe."
  //   >
  //     <Tabs defaultValue={FormVersion.DATABASE_URL}>
  //       <TabsList className="w-full grid grid-cols-2">
  //         <TabsTrigger value={FormVersion.DATABASE_URL}>DATABASE_URL</TabsTrigger>
  //         <TabsTrigger value={FormVersion.MANUAL}>Enter Manually</TabsTrigger>
  //       </TabsList>

  //       <TabsContent value={FormVersion.DATABASE_URL}>
  //         <DatabaseURLForm onSubmit={handleSubmit} />
  //       </TabsContent>
  //       <TabsContent value={FormVersion.MANUAL}>
  //         <ManualDetailsForm onSubmit={handleSubmit} />
  //       </TabsContent>
  //     </Tabs>
  //   </Page>
  // )
  return <Page title="Coming soon"></Page>
}
