import type { ActionType, State } from 'common/src/chainpipe/redux'
import { produce } from 'immer'

type StateWithInitial = State & {
  initialStateHasBeenSet: boolean
  initialCountsHaveBeenSet: boolean
}

export const reducer = (state: StateWithInitial, action: ActionType) => {
  // @ts-expect-error
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_INITIAL_STATE': {
        if (!state.initialStateHasBeenSet) {
          return {
            ...action.payload,
            initialStateHasBeenSet: true,
          }
        } else {
          return {
            ...state,
          }
        }
      }

      case 'SET_INITIAL_STATE_COUNTS': {
        if (!draft.initialCountsHaveBeenSet) {
          draft.initialCountsHaveBeenSet = true
          for (const item of action.payload) {
            if (draft.abiFragments[item.sighash]) {
              draft.abiFragments[item.sighash].totalCount = +item.count_events
            }
          }
          return draft
        }
        return state
      }

      case 'UPDATE_PROGRESS': {
        const sh = draft.abiFragments[action.payload.sighash]
        if (!sh) {
          return draft
        }

        const newCoveredBlockspace = sh.coveredBlockspace + action.payload.blockCount
        const newMissingBlockspace = Math.max(sh.missingBlockspace - action.payload.blockCount, 0)
        draft.abiFragments[action.payload.sighash] = {
          ...sh,
          coveredBlockspace: sh.coveredBlockspace + action.payload.blockCount,
          missingBlockspace: newMissingBlockspace,
          active: newMissingBlockspace > 1,
          totalCount: sh.totalCount + action.payload.newEventsSeen,
          progressRatio: newCoveredBlockspace / (newCoveredBlockspace + newMissingBlockspace),
        }
        return draft
      }
      default: {
        return state
      }
    }
  })
}
