import { SourceTypeID } from 'common'
import { Plus } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { type RFC, Table, TBody, Td, Th, THead, Tr } from 'ui'
import { ButtonLink } from '../../../components/button'
import { ContractWithAddressCell } from '../../../components/cells'
import { Page } from '../../../components/page'
import { useProject } from '../../../contexts'
import { namedURLs } from '../../../lib/named-routes'
import { trpc } from '../../../lib/trpc'

const ListSourcesPage: RFC<{}> = function ListSourcesPage(_props) {
  const params = useParams()
  const activeProject = useProject()

  const { data } = trpc.evmContractsV1.list.useQuery(
    {
      project: { id: activeProject!.id },
    },
    { enabled: !!activeProject?.id },
  )

  const navigate = useNavigate()

  return (
    <Page
      title="Sources"
      action={
        <ButtonLink to="new" leftIcon={<Plus className="w-4 h-4" />}>
          New Source
        </ButtonLink>
      }
    >
      <Table>
        <THead>
          <Tr>
            <Th>Contract</Th>
            <Th right>ABI Fragments</Th>
          </Tr>
        </THead>
        <TBody>
          {(data || []).map((item) => (
            <Tr
              className="cursor-pointer"
              key={item.id}
              onClick={() =>
                navigate(
                  namedURLs.sources.show({
                    sourceID: SourceTypeID.EVM_CONTRACT,
                    id: item.id,
                  }),
                )
              }
            >
              <ContractWithAddressCell network={item.network} name={item.name} address={item.address} />
              <Td right>{item.abiFragmentCount}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </Page>
  )
}

export default ListSourcesPage
