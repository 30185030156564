import clsx from 'clsx'
import type { RFC } from 'common-web'
import React, { forwardRef } from 'react'

const Menu: RFC = forwardRef<HTMLDivElement, { className?: string; children: any }>(
  ({ children, className = undefined, ...props }, ref) => (
    <div ref={ref} className={clsx('flex space-x-2 border-b border-gray-200 -mb-1', className)} {...props}>
      {children}
    </div>
  ),
)

const Tab: RFC<{
  selected?: boolean
}> = forwardRef<HTMLDivElement, { className?: string; selected?: boolean; children: any }>(
  ({ className = undefined, selected = false, children, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        'text-base px-1.5 py-2.5',
        {
          'font-medium border-blue-500 border-collapse !ring-0 border-b-4 text-black': selected,
          'text-gray-700': !selected,
        },
        className,
      )}
      {...props}
    >
      <div className="select-none hover:bg-gray-200/80 active:bg-gray-200 active:shadow-inner hover:text-gray-800 py-1.5 px-3 rounded cursor-pointer">
        {children}
      </div>
    </div>
  ),
)

export const TabNav = {
  Menu,
  Tab,
}
