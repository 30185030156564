import type React from 'react'
import { useState } from 'react'
import { Dialog, PlanCardFull, type PlanProps, plans, useCenterAbsoluteUrl } from 'ui'
import { Page } from '../../components'
import { StripeCheckout } from '../../components/stripe-checkout'
import { useProjectId } from '../../contexts'
import { trpc } from '../../lib'

function PlanPage() {
  const projectId = useProjectId()
  const postCheckoutRedirectUrl = useCenterAbsoluteUrl({
    subdomain: 'dashboard',
    path: '/api/stripePostCheckout',
  })

  const enterprisePlanContactUrl = useCenterAbsoluteUrl({
    subdomain: null,
    path: '/contact?intent=custom-plan',
  })

  const currentPlanQuery = trpc.dashboard.plans.current.useQuery(
    {
      projectId: projectId!,
    },
    { enabled: !!projectId },
  )
  const plansInDbQuery = trpc.dashboard.plans.list.useQuery(
    { projectId: projectId! },
    {
      enabled: !!projectId,
      placeholderData: [],
    },
  )

  const checkoutLoadingComponent = <div>Loading...</div>
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false)
  const [paymentModalContents, setPaymentModalContents] = useState<React.ReactNode>(checkoutLoadingComponent)

  const paymentModal = (
    <Dialog
      open={paymentModalOpen}
      setOpen={setPaymentModalOpen}
      contentClassName="sm:max-w-lg"
      onClose={() => {
        setPaymentModalContents(checkoutLoadingComponent)
      }}
    >
      {paymentModalContents}
    </Dialog>
  )

  const handleButtonClick = ({ plan }: { plan: PlanProps }) => {
    if (!projectId) {
      return
    }
    if (plan.slug === 'enterprise') {
      if (typeof window === 'undefined') {
        throw new Error('Cannot access window from this context')
      }
      window.open(enterprisePlanContactUrl, '_blank')?.focus()

      return
    } else {
      const stipeCheckoutContent = (
        <StripeCheckout planSlug={plan.slug} returnUrl={postCheckoutRedirectUrl} projectId={projectId} />
      )
      setPaymentModalContents(stipeCheckoutContent)
    }

    setPaymentModalOpen(true)
  }

  return (
    <Page title="Plans" description="Start building for free, upgrade with a click when it’s time to scale.">
      <div className="grid grid-cols-1 md:grid-cols-3 items-stretch container w-full mb-16 gap-4">
        {(plans || []).map((plan) => {
          const plansNotLoaded = !(currentPlanQuery.isSuccess && plansInDbQuery.isSuccess)
          const planMatchesCurrentPlan = currentPlanQuery.data?.slug === plan.slug
          const dbPlan = plansInDbQuery.data?.find((x) => x.slug === plan.slug)
          if (!dbPlan) {
            return null
          }

          const buttonText = planMatchesCurrentPlan
            ? 'Current Plan'
            : plan.slug === 'enterprise'
              ? 'Contact Us'
              : 'Select'

          return (
            <PlanCardFull
              name={plan.name}
              description={plan.description}
              slug={plan.slug}
              className="col-span-1 md:max-w-[450px]"
              price={plan.price}
              key={plan.name}
              onSubmit={() => handleButtonClick({ plan })}
              buttonText={buttonText}
              sellingPoints={plan.sellingPoints}
              disabled={plansNotLoaded || planMatchesCurrentPlan}
            />
          )
        })}
        {paymentModal}
      </div>
    </Page>
  )
}

export default PlanPage
