'use client'

import { useState } from 'react'
import type { RFC } from 'common-web'
import { useCenterAbsoluteUrl } from '../components/use-center-apex'
import { CenterLogoAlt } from '../icons'
import { CenterLogoAndWordmark } from '../primitives'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './dropdown-menu'

const links = [
  {
    name: 'What We Do',
    href: useCenterAbsoluteUrl({ subdomain: null, path: '/what-we-do' }),
    target: undefined,
  },
  {
    name: 'Documentation',
    href: useCenterAbsoluteUrl({ subdomain: null, path: '/docs' }),
    target: '_blank',
  },
  {
    name: 'Customer Stories',
    href: useCenterAbsoluteUrl({ subdomain: null, path: '/customer-stories' }),
    target: undefined,
  },
  {
    name: 'Blog',
    href: useCenterAbsoluteUrl({ subdomain: 'blog', path: '/' }),
    target: '_blank',
  },
]

const Hamburger: RFC<{ open: boolean }> = ({ open }) => {
  return (
    <div className={`hamburger ${open ? 'is-open' : ''}`}>
      <div className="hamburger__bar" />
      <div className="hamburger__bar" />
      <div className="hamburger__bar" />
    </div>
  )
}

interface NavProps {
  renderLink: (props: React.HTMLProps<HTMLAnchorElement>) => React.ReactNode
}

export const Nav: RFC<NavProps> = ({ renderLink }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  return (
    <nav className="flex justify-between items-center px-5 py-5 lg:border-b-2 border-color-[#D9D9D9/50] sticky top-0 bg-white z-50">
      <DropdownMenu onOpenChange={(open) => setMenuOpen(open)}>
        <DropdownMenuTrigger className="lg:hidden">
          <Hamburger open={menuOpen} />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="ml-4">
          {links.map((link) => {
            return (
              <DropdownMenuItem key={link.name}>
                {renderLink({
                  href: link.href,
                  target: link.target,
                  children: <a className="flex flex-grow">{link.name}</a>,
                })}
              </DropdownMenuItem>
            )
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      {renderLink({
        href: useCenterAbsoluteUrl({ subdomain: null, path: '/' }),
        children: <CenterLogoAndWordmark className="h-8 hidden lg:block cursor-pointer" />,
      })}
      <div className="lg:hidden">
        {renderLink({
          href: useCenterAbsoluteUrl({ subdomain: null, path: '/' }),
          children: <CenterLogoAlt className="cursor-pointer" />,
        })}
      </div>
      <div className="hidden lg:flex mr-auto ml-12 xl:ml-20">
        {links.map((link) =>
          renderLink({
            key: link.name,
            href: link.href,
            target: link.target,
            children: (
              <a
                className="text-md text-gray-700 hover:text-brand-primary hover:bg-gray-100 px-2 py-2 font-medium [&:not(:last-child)]:mr-[1.5rem] cursor-pointer "
                target={link.target}
              >
                {link.name}
              </a>
            ),
          }),
        )}
      </div>
      <div>
        {renderLink({
          href: useCenterAbsoluteUrl({ subdomain: 'auth', path: '/login' }),
          children: <button className="secondary mr-3 font-bold px-[1.5rem] hidden lg:inline-block">Log in</button>,
        })}
        {renderLink({
          href: useCenterAbsoluteUrl({ subdomain: 'auth', path: '/signup' }),
          children: <button className="primary font-bold px-[2.0rem]">Sign up</button>,
        })}
      </div>
    </nav>
  )
}
